import ButtonCloseModal from '../../Buttons/ButtonCloseModal'
import VisaCard from '../../VisaCard'
import ButtonBack from '../../Buttons/ButtonBack'


function TransferCard({ openModal }) {

    return (
        <>
            <div className="fixed w-full top-0 laptop:top-1/2 laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2  flex justify-evenly items-center h-full  laptop:max-h-[650px] laptop:rounded-2xl bg-[#3a383d] laptop:bg-transparent laptop:max-w-[550px] z-20">

                <form className="bg-[#3a383d] laptop:bg-[#D4D4D4] w-full max-w-[550px] px-5 py-5 h-full laptop:rounded-xl">
                    <VisaCard className="mx-auto mb-5 laptop:mb-0" />


                    <div className="space-y-4">
                        <input type="text" className="block w-full px-5 py-4 rounded-xl outline-none" placeholder="Номер карты" />

                        <input type="text" className="block w-full px-5 py-4 rounded-xl outline-none" placeholder="Сумма" />

                        <textarea className="block w-full px-5 py-4 rounded-xl outline-none resize-none" placeholder="Комментарий"></textarea>

                        <button className="px-14 py-3 font-medium block mx-auto text-lg rounded-xl bg-[#FFDD2D]">
                            Отправить
                        </button>
                    </div>
                </form>

                <ButtonBack className={'top-8 left-4'} onClick={openModal} />
                <ButtonCloseModal onClick={openModal} position='hidden laptop:block -top-5 -right-5' />

            </div>

        </>
    )
}


export default TransferCard