import { GrFormPrevious } from "react-icons/gr";
import React from "react";

const PrevButton = () => {
  return (
    <button className="bg-[#CCCCCC] rounded-full hover:bg-[#245626]">
      <GrFormPrevious className="text-5xl text-white" />
    </button>
  );
};

export default PrevButton;
