import infoPayment from "../../images/infoPaymentMain.png";
import next from "../../images/nextImage.png";
import cards from "../../images/cardsImage.png";
import firstMob from "../../images/firstMainMobBg.png";
import secondMob from "../../images/secondMainMobBg.png";
import thirdMob from "../../images/thirdMainMobBg.png";
import fourMob from "../../images/fourMainMobBg.png";
import React, { useEffect, useState } from "react";
import SliderNews from "../ux/SliderNews";
import '../../cusstomScrollbar.css'

const MainPage = () => {
  const [news, setNews] = useState([]);

  const getNewsInfo = async () => {
    try {
      const response = await (
        await fetch("https://api.open-trade.garcom.kz/wallet/production/v1/news")
      ).json();
      setNews(response.news);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNewsInfo();
  }, []);

  return (
    <div className="font-monserrat">
      <div className="mobile:bg-mobileMainBg-image tablet:bg-mainBg-image bg-no-repeat bg-center bg-cover w-full h-[70vw] tablet:h-[38vw] desktop:h-[30vw] flex items-center font-monserrat">
        <div className="w-[90%] mx-auto">
          <div className="w-[95%] desktop:w-[90%] desktop2:w-[87%] flex flex-col tablet:flex-row justify-between items-start tablet:items-center">
            <p className="text-white text-xl laptop:text-2xl desktop:text-3xl desktop2:text-5xl font-bold"> Финансовая свобода в <br /> вашем смартфоне{" "} </p>
            <div className=" tablet:mt-32 laptop:mt-40">
              <p className="text-white text-xl desktop:text-2xl desktop2:text-3xl leading-5 mt-3 tablet:m-0">
                OPEN TRADE <br />{" "}
                <span className="text-sm desktop2:text-lg"> Просто. Удобно. Без границ. </span>
              </p>
              <button className="py-2 px-3 bg-[#FFDD2D] rounded-md desktop2:text-xl font-bold hover:bg-yellow-500 transition-colors duration-300 mt-5 tablet:ml-4 laptop:ml-24 desktop2:ml-32">
                Создать кошелек
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[90%] mx-auto mt-20">
        <p className="text-xl tablet:text-2xl desktop2:text-4xl">ДОБРО ПОЖАЛОВАТЬ В МИР <br /> БЕЗГРАНИЧНЫХ ВОЗМОЖНОСТЕЙ С <br /> OPENTRADE!{" "}</p>
        <p className="my-10 desktop2:text-2xl">ОПЛАЧИВАЙТЕ СЧЕТА, ПОКУПКИ, ПУТЕШЕСТВИЯ, ИГРЫ И МНОГОЕ <br /> ДРУГОЕ ВОДНО КАСАНИЕ.{" "}</p>
        <div className="flex hidden-scroll overflow-auto">
          <img loading="eager" src={infoPayment} alt="infoPayment" className="hidden tablet:block w-[45%] desktop:w-[35%] object-cover mr-2 rounded-3xl" />
          <div className="flex gap-2 tablet:hidden mr-2">
            {[{ img: firstMob, title: "Игры" }, { img: secondMob, title: "Музыка" }, { img: thirdMob, title: "Транспорты" }, { img: fourMob, title: "Мобильная связь" }].map((el, index) => (
              <div key={index} className="w-[45vw]">
                <img loading="eager" src={el.img} alt="services" className="w-full h-[45vw] object-cover rounded-[50px]" />
                <p>{el.title}</p>
              </div>
            ))}
          </div>
          <img loading="eager" src={next} alt="next" className="w-[20%] tablet:w-[5%] tablet:h-[5%] self-center" />
          <div className="bg-gray-200 p-4 rounded-[40px] flex flex-shrink-0 flex-col justify-center tablet:justify-between">
            <p className="font-bold leading-5 desktop2:text-xl"> Оплачивай <br /> без комиссий{" "} </p>
            <p className="leading-5 desktop2:text-xl my-3 laptop:m-0">Пополняй и управляй: <br /> легко с Open Trade!{" "}</p>
            <button className="bg-[#124F1E] text-sm desktop2:text-xl text-white p-1 rounded-xl w-[60%] self-end hover:bg-green-700 transition-colors duration-300">
              подробнее
            </button>
          </div>
        </div>
      </div>
      <div className="tablet:w-[90%] bg-[#245626] mt-14">
        <div className="w-[90%] mx-auto pt-14 pb-[37%] tablet:pb-[17%] text-white">
          <p className="tablet:w-[55%] laptop:w-[55%] text-justify desktop:text-2xl" style={{ textIndent: "1rem" }} >
            “Мы стремимся делать финансовые операции более удобными, безопасными
            и доступными для всех. Наша команда нацелена на инновации и создание
            современных инструментов, которые помогают нашим пользователям
            эффективно управлять своими финансами.”
          </p>
          <div className="relative">
            <p className="mt-10 text-2xl">СЕО</p>
            <img loading="eager" src={cards} alt="cards" className="w-[70%] tablet:w-[40%] mb-[-20px] absolute top-[-50%] tablet:top-[-150%] right-0 desktop:top-[-100%] tablet:left-52" />
          </div>
        </div>
      </div>
      <div className="w-[90%] mx-auto">
        {/* <p className="mt-[10%] text-2xl laptop:text-3xl">
          Оптимизировано для быстрого доступа и <br /> управления вашими счетами
        </p>
        <div className="w-[600px] max-w-full laptop:w-[70%] desktop:w-[50%] flex flex-col tablet:flex-row items-start tablet:items-center justify-between bg-[#EAEAEA] p-10 rounded-[50px] shadow-[1px_5px_5px_1px_#9a9a9a] mt-10">
          <div>
            <p className="text-2xl tablet:text-5xl desktop2:text-6xl"> Open Trade </p>
            <p className="desktop2:text-xl my-3 tablet:m-0"> Электронный кошелек без хлопот-{" "} <br className="hidden tablet:block" /> простота в каждой транзакции. </p>
          </div>
          <div className="w-[70%] tablet:w-[30%] flex flex-col gap-2">
            <div className="w-full bg-[#D9D9D9] border border-[#C6C6C6] py-5 rounded-3xl"></div>
            <div className="w-full bg-[#D9D9D9] border border-[#C6C6C6] py-5 rounded-3xl"></div>
          </div>
        </div> */}
        <p className="mt-[10%] text-xl tablet:text-2xl leading-10"> Познавай финансовый мир в одном месте:{" "} <br className="hidden tablet:block" /> Новости о криптовалютах, банках и <br className="hidden tablet:block" /> инновациях!{" "}
          <button className="bg-[#EAEAEA] py-3 px-5 rounded-3xl text-sm font-bold shadow-inner tablet:ml-5 hover:bg-gray-300 transition-colors duration-300">
            Посмотреть
          </button>
        </p>
        <SliderNews news={news} />
      </div>
    </div>
  );
};

export default MainPage;
