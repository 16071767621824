import { MdBorderColor } from "react-icons/md";

const PERSONAL_DATA = {
    IIN: 'ИИН',
    fullName: 'ФИО',
    nickname: 'Никнейм',
    IDNumber: '№ удостоверения личности',
    email: 'Email',
    adress: 'Адрес',
    phoneNumber: 'Номер телефона ',
    close: 'Сохранить данные',
    personalData: 'Личные данные',
    currentBallance: 'Текущий баланс',
    identyfiInformation: 'Информация об опциях идентификации',
    support: 'Поддержка',
    help:'Справка',
    daylyLimit: 'Ваш дневной лимит:',
    customStyles: {
      content: {
        maxWidth: '95dvw',
        minHeight: '95dvh',
        overflow: 'auto',
        border: '0',
        background: 'none',
        top: 0,
      },
      overlay: {
        backdropFilter: 'blur(5px)',
        top: '70px',
        position: 'fixed',
        zIndex: '10',
      },
    },
    personalStyles : {
      overlay: {
        zIndex: '11',
        background: 'none',
      },
      content: {
        background: 'none',
        border: 0,
        overflow: 'hidden',
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }
}

export default PERSONAL_DATA;