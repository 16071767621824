import { ComposedChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

const data = [
    {
        name: 'A',
        pv: 0,
        amt: 0,
    },
    {
        name: 'B',
        pv: 50,
        amt: 30,
    },

    {
        name: 'C',
        pv: 10,
        amt: 50,
    },
    {
        name: 'D',
        pv: 20,
        amt: 90,
    },
    {
        name: 'E',
        pv: 30,
        amt: 50,
    },
];

export default function ChartMobile({ className, isAuthorizedMain }) {

    const colorVar = window.innerWidth >= 1024 || isAuthorizedMain ? 'black' : 'white'

    return (
        <>
            <div className="bg-[#4D4D4D] w-full flex laptop:hidden justify-end py-1 px-4 gap-6 rounded-lg text-white">
                <span className="relative before:absolute before:w-2 before:h-1 before:top-1/2 before:-left-3 before:-translate-y-1/2 before:bg-[#34FF3C]">Доход</span>
                <span className="relative before:absolute before:w-2 before:h-1 before:top-1/2 before:-left-3 before:-translate-y-1/2 before:bg-[#FF1705]">Расходы</span>
            </div>
            <ResponsiveContainer className={className} width="100%" height={250}>
                <ComposedChart
                    width={100}
                    height={300}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: -30,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis tick={{ fill: `${colorVar}`, fontSize: 15, }} tickLine={false} dataKey="name" />
                    <YAxis tick={{ fill: `${colorVar}`, fontSize: 15, }} tickLine={false} type="number" domain={[0, 100]} tickFormatter={(tick) => (tick !== 0 ? tick : '')} />

                    <Area dataKey="pv" fill="#34FF3C" stroke='#34FF3C' strokeWidth={3} />
                    <Area dataKey="amt" fill="#FF1705" stroke='red' strokeWidth={3} />
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
}
