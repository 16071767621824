import VisaImg from '../../images/visaCard.png'

function VisaCard({ className }) {
    return (
        <div className={`relative w-full max-w-[500px] h-[220px] min-[475px]:h-[250px] tablet:h-[300px] ${className}`}>
            <img src={VisaImg} alt="visacard" className="absolute w-full h-full inset-0" />
            <div className="cardInfo absolute bottom-8 left-4 min-[375px]:bottom-8 min-[375px]:left-6 tablet:bottom-12 tablet:left-10">
                <p className="cardNumber min-[375px]:mb-1 text-white text-lg min-[425px]:text-2xl tablet:text-2xl">4400 **** **** 0017</p>
                <p className="min-[375px]:mb-2 text-white text-xl min-[425px]:text-2xl tablet:text-3xl">Текущий баланс</p>
                <p className="balance text-[#0AF42F] text-xl min-[425px]:text-2xl tablet:text-3xl">1.000.000KZT(2100$)</p>
            </div>
        </div>
    )
}

export default VisaCard



