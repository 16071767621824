import React from 'react'
import MODAL_CONTROLS from '../../../constants/modalControls.constants';
import cardIcon from '../../../images/personalData/cardIcon.png';
import cardName from '../../../images/personalData/cardName.png';
import chip from '../../../images/personalData/chip.png';
import avatar from '../../../images/personalData/avatar.png';
import friends from "../../../images/friends.png";
import DEPOSITE from '../../../constants/deposite.constants';
import Footer from '../../Footer'

export default function Deposite({ isOpen, setIsOpen, user = {} }) {
    const [subModal, setSubModal] = React.useState('');
    const [value, setValue] = React.useState('');
    const [cashNumber, setCashNumber] = React.useState('');
    const [comment, setComment] = React.useState('');

    function handleClick() {
        console.log(`send reauest ${{ value, cashNumber, comment }}`)
    }

    return (
        <>
            {isOpen && <><div onClick={() => setIsOpen(false)} className='backdrop-blur-[5px] fixed top-0 laptop:top-16  left-0  w-dvw h-dvh z-20 flex flex-col items-center text-Montserrat'>
                <div onClick={(e) => { subModal ? setSubModal('') : setIsOpen(false); e.stopPropagation() }} className='absolute w-10 h-10 z-30 bg-[#CCCCCC] rounded-full top-4 left-4 laptop:hidden text-white'>

                </div>
                <div className='mt-0  h-dvh laptop:h-fit bg-[#3A383D] w-dvw w-full laptop:w-1/3 rounded-xl desktop:w-1/4 laptop:bg-[#D4D4D4] flex flex-col items-center'>
                    <section onClick={(e) => e.stopPropagation()} className='laptop:hidden  w-[400px] h-[250px]'>
                        <div className='relative bg-[--profile_card_bg] rounded-xl text-white mx-5   h-[250px] mt-14 grid items-end'>
                            <div className='absolute grid grid-cols-2 pr-3 pl-6 pt-1 pb-3 h-full w-full'>
                                <img loading="eager" alt='' src={cardName} className='h-fit mt-4' />
                                <div></div>
                                <img loading="eager" alt='' src={cardIcon} className='absolute w-[70%] top-2 right-1' />
                                <div></div>
                                <div className='grid place-items-end'>
                                    <img loading="eager" src={chip} alt='' className='w-1/3 h-fit' />
                                </div>
                            </div>
                            <div className='p-5'>
                                {user.card ? <><div id='card_number'>{user.prefix} **** **** {user.prefix}</div>
                                    <div className='text-[#0AF42F]'>{
                                        `${Intl.NumberFormat('de-DE', { style: 'currency', currency: 'KZT' }).format(user.kzt)}
                            (${Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(user.usd)})`}
                                    </div></> :
                                    <><div>
                                        У вас нет карты
                                    </div></>}
                            </div>
                        </div>
                    </section>
                    <section onClick={(e) => e.stopPropagation()} className='w-full'>
                        <div className='hidden mx-10 laptop:flex flex-col gap-16 mt-10'>
                            <section className=' flex flex-col '>
                                <div className='flex justify-center rounded-full ' >
                                    <img loading="eager" alt='' src={user.avatar || avatar} className='max-w-40' />
                                </div>
                            </section>
                        </div>
                    </section>
                    <form onClick={(e) => e.stopPropagation()} className='w-4/5 grid gap-3 place-items-center text-[--white] mt-20 laptop:mt-0'>
                        {subModal === MODAL_CONTROLS.friend && <img loading="eager" src={friends} className='laptop:hidden'>

                        </img>}
                        {subModal && <>
                            <input type={MODAL_CONTROLS.friend === subModal ? 'text' : 'number'} value={value} onChange={e => setValue(e.currentTarget.value)}
                                placeholder={MODAL_CONTROLS.friend === subModal ? 'Никнейм' : 'Номер карты'} className='py-3.5 flex justify-center w-2/3 laptop:w-full bg-[#fff] laptop:bg-[#245626] text-[#3A383D] laptop:text-[#FCFBFF69] py-2 laptop:py-5 px-10 rounded-xl inline-block'
                            >
                            </input>
                            <input type='number' value={cashNumber} onChange={e => setCashNumber(e.currentTarget.value)}
                                placeholder='Сумма' className='py-3.5 flex justify-center w-full laptop:w-2/3  bg-[#fff] laptop:bg-[#245626] text-[#3A383D] laptop:text-[#FCFBFF69] py-2 laptop:py-5 px-10 rounded-xl inline-block'
                            >
                            </input>
                            <textarea type='text' value={comment} onChange={e => setComment(e.currentTarget.value)}
                                placeholder='Комментарий' className='py-10 w-full laptop:w-2/3  bg-[#8B8C8B8F] laptop:bg-[#6B896C] text-[#3A383D] laptop:text-[#FCFBFF69] py-2 laptop:py-5 px-10 rounded-xl inline-block'
                            >
                            </textarea>
                            <button type='submit'
                                className='mb-14 w-1/3 laptop:w-full shadow-inner shadow-gray-900 bg-[#34FF3C] laptop:bg-[--btn_color] py-3 rounded-2xl text-xl text-[#3A383D] laptop:text-black'
                            >{MODAL_CONTROLS.friend === subModal ? 'Попросить' : 'Отправить'}
                            </button>
                        </>}
                    </form>
                    {!subModal && <section onClick={(e) => e.stopPropagation()} className='pb-14 w-full grid gap-5 px-5 text-xl font-bold'>
                        <button onClick={() => setSubModal(MODAL_CONTROLS.friend)} type='button' className='py-12 w-full bg-[white] rounded-xl text-[#4D4D4D] text-3xl' >{DEPOSITE.askFriend}</button>
                        <button onClick={() => setSubModal(MODAL_CONTROLS.bank)} type='button' className='py-12 w-full bg-[#8B8C8B8F] rounded-xl text-[#FFFFFF85] text-3xl' >{DEPOSITE.bankCard}</button>
                    </section>}
                </div>
                <div onClick={e => e.stopPropagation()} className='w-dvw absolute bottom-0 block laptop:hidden z-50'>
                    <Footer />
                </div>
            </div></>}
        </>
    )
}