import { useState } from 'react'
import { useCreditCardValidator, images } from 'react-creditcard-validator';
import ButtonCloseModal from '../Buttons/ButtonCloseModal'
import VisaCard from '../VisaCard'
import ButtonBack from '../Buttons/ButtonBack'
import { addCard } from '../../../API-services/user.services'
import Done from '../../../images/done.png'


function AddCard({ openModal }) {

    const [cardHolder, setCardHolder] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [exp_date, setExpDate] = useState('')
    const [error, setError] = useState('')
    const [messageModal, setMessageModal] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [messageHint, setMessageHint] = useState('')



    const onlyText = (e) => {
        const inputValue = e.target.value;
        setError('')
        // Regular expression to allow only letters (both upper and lowercase)
        const textOnly = inputValue.replace(/[^a-zA-Z\s]/g, '');
        setCardHolder(textOnly);
    };

    const {
        getCardNumberProps,
        getCardImageProps,
        getExpiryDateProps,
        meta: { erroredInputs }
    } = useCreditCardValidator();



    const handleCardRequest = async (e) => {
        e.preventDefault();
        const words = cardHolder.trim().split(' ')

        if (words.length !== 2) {
            setError('Укажите свое имя или фамилию')
            return
        }
        const [firstName, lastName] = words;

        if (firstName.length < 2 || firstName.length > 20) {
            setError('Имя должно содержать от 2 до 20 символов.');
            return;
        }

        if (lastName.length < 2 || lastName.length > 20) {
            setError('Фамилия должна содержать от 2 до 20 символов.');
            return;
        }

        const cardUserData = {
            "bank_number": cardNumber,
            "owner": cardHolder,
            "exp_date": exp_date.split(" ").join("")
        }


        const { success, message } = await addCard(cardUserData)

        setIsSuccess(success)
        setMessageModal(true)

        if (success) {
            setCardHolder('')
            setCardNumber('')
            setExpDate('')

            setMessageHint("Ваша банковская карта добавлена!")
            // setSuccessAdded(true)
        } else {
            // setSuccessAdded(false)
            setMessageHint('Не удалось добавить вашу банковскую карту!')
        }

        setTimeout(() => {
            setMessageModal(false)
            setMessageHint('')
        }, 2500)


    }
    return (
        <>

            <div className="fixed w-full laptop:w-[500px] top-0 left-0 laptop:top-1/2 laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2 bg-[#3a383d] flex flex-col  laptop:flex-row justify-evenly items-center h-full  laptop:max-h-[660px] laptop:rounded-2xl z-20">

                <form onSubmit={handleCardRequest} className="bg-[#3a383d]  w-full max-w-[600px] laptop:max-w-[500px] px-5 py-4 laptop:py-8 h-full laptop:rounded-xl">
                    <VisaCard className="mx-auto left-1 mb-6" />


                    <div className="space-y-4 relative">
                        <svg {...getCardImageProps({ images })} className="absolute right-4 top-5" />
                        <input required className="block w-full px-5 py-4 rounded-xl border outline-none" {...getCardNumberProps({ value: cardNumber, onChange: (e) => setCardNumber(e.target.value) })} />
                        <small className="text-red-400">{erroredInputs.cardNumber && "Неправильный ввод данных карты"}</small>

                        <div className="flex gap-4 ">
                            <label className="block w-9/12 tablet:w-10/12 laptop:w-9/12">
                                <input required type="text" pattern="[A-Za-z\s]+" className="w-full px-5 py-4 rounded-xl border outline-none uppercase" placeholder="ВЛАДЕЛЕЦ КАРТЫ" value={cardHolder} onChange={onlyText} />
                                <small className="text-red-400">{error}</small>
                            </label>
                            <label className="block w-3/12 tablet:w-2/12 laptop:w-3/12">
                                <input required type="text" className="w-full text-center py-4 rounded-xl border outline-none" {...getExpiryDateProps({ value: exp_date, onChange: (e) => setExpDate(e.target.value) })} />
                                <small className="text-red-400">{erroredInputs.expiryDate && "Неправильный срок карты"}</small>
                            </label>
                        </div>

                        <button type="submit" className="px-14 py-3 font-medium block mx-auto text-lg rounded-xl bg-[#FFDD2D]">
                            Добавить Карту
                        </button>
                    </div>
                </form>

                {
                    messageModal &&
                    <p className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center bg-[#655D65B2] backdrop-blur py-4 px-6 w-2/3 laptop:w-auto rounded-md">
                        {isSuccess && <img className='mx-auto' src={Done} alt="done" />}
                        {messageHint}
                    </p>
                }

                <ButtonBack onClick={openModal} className={'top-10 left-4 min-[375px]:top-10 min-[375px]:left-4 tablet:left-10'} />
                <ButtonCloseModal onClick={openModal} position='hidden laptop:block -top-5 -right-5' />
            </div >

        </>
    )
}


export default AddCard