import BankIcon from '../../../images/bankIcon.png'

function RecentTransactionItem({ sum, operationType }) {
    return (
        <div className={`relative flex justify-between items-center px-4 laptop:px-7 py-5 ${operationType ? 'bg-[#ccdfcd]' : 'bg-[#FFB42E]'} rounded-[10px] text-lg`}>
            <div className="absolute top-1/2 left-2 -translate-y-1/2 hidden laptop:block bg-white w-[72px] rounded-2xl">
                <img src={BankIcon} alt="icon" />
            </div>
            <p className="laptop:ms-[66px]">Forte Bank</p>
            <span>{operationType ? '+' : '-'}{sum}$</span>
        </div>
    )
}

export default RecentTransactionItem