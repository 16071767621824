const FOOTER = {
    mobileMenu: {
        home: 'home',
        profile: 'profile',
        wallet: 'wallet',
        transactions: 'transactions',
    },
    footerMenu: [
        {
            title: 'Справочный центр',
            link:'#',
            children: [
                {link: '/about-us', title: 'О нас'},
                {link: '/identify', title: 'О лимитах'},
                {link: '/public-offer', title: 'Публичная оферта'},
                {link: '/faq', title: 'Часто задаваемые вопросы'},
            
          ]
        },
        {
            title: 'Идентификация',
            link:'/identify',
            children: [
                'Упрощенная идентификация',
                'Полная идентификация',
            ]
        },
        {
            title: 'Новости',
            link:'/news/1',
            children: [
                'Главные новости',
                'Игровой блог',
                'Спортивный блог',
            ]
        }
    ],
    main: 'Главная',
    deposite: 'Пополнить',
    contacts: {
        adress: 'РК, г. Алматы, ул. Аль-Фараби, Holding «Open Trade»',
        phoneList: [
            {title: 'call-центр', description: '+7 (727) 355 0356'},
            {title: 'по идентификации', description: '+7 (777) 715 9510'},
            {title: 'техническая поддержка', description: '+7 (707) 332 8450'},
        ],
        emailList: [
            {description:'support@open.kz', title: 'техническая поддержка клиентов'},
            {description: 'info@open.kz', title: 'вопросы по сотрудничеству'},
        ],
    },
    socialLinks: {
        instagram: '',
        telegramm: '',
        tiktok: '',
        vk: ''
    },
    appStoreLink: '',
    googlePlayLink: '',
}

export default FOOTER;