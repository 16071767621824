import RecentTransactionItem from "./RecentTransactionItem"

function RecentTransactions() {
    return (
        <div className="relative w-full">
            <p className="py-4 mb-2 text-center rounded-[10px] shadow-[0px_10px_4px_0px_#00000040] laptop:shadow-[10px_10px_4px_0px_#00000040] text-black laptop:text-white text-2xl font-normal laptop:font-light bg-[#FFFFFF] laptop:bg-[#204D22]">Последние транзакции</p>

            <div className="tablet:max-w-2xl laptop:w-11/12 mx-auto space-y-4 mt-5 laptop:mt-2">
                {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => <RecentTransactionItem key={num} sum={num} operationType={num % 2 === 0} />)
                }
            </div>
        </div>

    )
}

export default RecentTransactions