import React from 'react'
import IDENTIFY from '../../constants/fullIdentify.constants'
import MODAL_CONTROLS from '../../constants/modalControls.constants';
import BackMobile from '../ui/BackMobile';
import YesSvg from '../../images/identify/Yes-svg';
import NoSvg from '../../images/identify/No-svg';
import PersonSvg from '../../images/identify/Person-svg';

export default function Identify({ setModalController, setIsOpen }) {
  return (
    <React.Fragment>
      <main className='flex flex-col items-center mx-8 relative laptop:mx-36 w-1/1' >
        <section className='laptop:mx-4 rounded-xl flex justify:center laptop:justify-end laptop:w-full py-auto laptop:py-28  laptop:bg-[--full_identify_block_bg]'>
          <img loading="eager" alt='logo' src='img/identify/logo.png' className='laptop:hidden ' />
          <BackMobile />
          <div className=' hidden laptop:flex flex-col items-center gap-2 laptop:gap-8 laptop:me-20 font-bold'>
            <h2 className='laptop:text-4xl text-white w-[35rem] text-center text-wrap'>{IDENTIFY.title}</h2>
            <span className='border-2 w-4/5' />
          </div>
        </section>
        <section className='grid desktop:w-full mt-28 gap-12 w-full'>
          <div className='flex justify-around text-2xl laptop:text-4xl'>
            <button className='px-[4vw] py-24 drop-shadow-xl laptop:py-5 laptop:px-24 rounded-xl bg-[--full_identify_block_bg] text-[--white] laptop:text-black laptop:bg-[--full_identify_btn_bg]'>
              <div>{IDENTIFY.simple}</div>
              <div>{IDENTIFY.idetify}</div>
            </button>
            <button className='px-[4vw] py-24 drop-shadow-xl laptop:py-5 laptop:px-24 rounded-xl bg-[--full_identify_block_bg] text-[--white] laptop:text-black laptop:bg-[--full_identify_btn_bg] '>
              <div>{IDENTIFY.full}</div>
              <div>{IDENTIFY.idetify}</div>
            </button>
          </div>
          <div className='hidden laptop:flex justify-center'>
            <button className='bg-[--full_identify_block_bg] rounded-md px-10 py-4 text-[--white] text-xl font-normal'>{IDENTIFY.identifyKnowledge}</button>
          </div>
        </section>
        <section className='relative mt-48 mb-20 pb-36 rounded-md bg-[--full_identify_content_bg] hidden laptop:flex flex-col items-around w-full'>
          <div className='absolute -top-28 flex justify-around w-full'>
            <div className='bg-[--full_identify_first_icon_bg] max-h-[220px] max-w-[220px] flex gap-5 flex-col justify-start items-center p-3 rounded-3xl'>
              <PersonSvg className='h-1/2' />
              <div className='grid place-items-center'>
                <span>{IDENTIFY.unidetifeid}</span>
                <span>{IDENTIFY.wallet}</span>
              </div>
            </div>
            <div className='bg-[--full_identify_second_icon_bg] max-h-[220px] max-w-[220px] flex gap-5 flex-col justify-start items-center p-3 rounded-3xl'>
              <PersonSvg className='h-1/2' />
              <div className='grid place-items-center'>
                <span>{IDENTIFY.simpled}</span>
                <span>{IDENTIFY.idetified}</span>
                <span>{IDENTIFY.wallet}</span>
              </div>
            </div>
            <div className='bg-[--full_identify_third_icon_bg] max-h-[220px] max-w-[220px] flex gap-5 flex-col justify-start items-center p-3 rounded-3xl'>
              <PersonSvg className='h-1/2' />
              <div className='grid place-items-center'>
                <span>{IDENTIFY.idetified}</span>
                <span>{IDENTIFY.wallet}</span>
              </div>
            </div>
          </div>
          <div className='pt-36 flex  justify-around'>
            <section className='grid gap-20'>
              {IDENTIFY.idetifyTextList.map((text, index) => (
                <div key={text} className='flex flex-col gap-4 text-xl items-center text-center'>
                  {index < 2 ? <YesSvg /> : <NoSvg />}
                  {!Array.isArray(text) ?
                    <span className='text-2xl max-w-[230px]'>{text}</span> :
                    text.map((el) => <span key={el} className='max-w-[230px]'>{el}</span>)
                  }
                </div>
              ))}
            </section>
            <section className='grid gap-20'>
              {IDENTIFY.idetifyTextList.map((text, index) => (
                <div key={text} className='flex flex-col gap-4 text-xl items-center text-center'>
                  {index < 4 ? <YesSvg /> : <NoSvg />}
                  {!Array.isArray(text) ?
                    <span className='text-2xl max-w-[230px]'>{text}</span> :
                    text.map((el) => <span key={el} className='max-w-[230px]'>{el}</span>)
                  }
                </div>
              ))}
            </section>
            <section className='grid gap-20'>
              {IDENTIFY.idetifyTextList.map((text, index) => (
                <div key={text} className='flex flex-col gap-4 text-xl items-center text-center'>
                  {index < 6 ? <YesSvg /> : <NoSvg />}
                  {!Array.isArray(text) ?
                    <span className='text-2xl max-w-[230px]'>{text}</span> :
                    text.map((el) => <span key={el} className='max-w-[230px]'>{el}</span>)
                  }
                </div>
              ))}
            </section>
          </div>
          <div className='absolute -bottom-4 flex justify-center w-full'>
            <button
              className='bg-[--btn_color] text-2xl px-16 py-2 rounded-xl'
              onClick={() => { setIsOpen(true); setModalController(MODAL_CONTROLS.onlineIdentify) }}
            >
              {IDENTIFY.passIdentification}
            </button>
          </div>
        </section>
      </main>
    </React.Fragment>
  )
}
