//comnponetns
import React from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import CreateWallet from "./components/ux/modal/CreateWallet";
import Welcome from "./components/ux/modal/Welcome";
import Deposite from "./components/ux/modal/Deposite";
import SmsCodeEnter from "./components/ux/modal/SmsCodeEnter";
//pages
import NewsPage from "./components/pages/NewsPage";
import MobileNewsPage from "./components/pages/MobileNewsPage";
import MainPage from "./components/pages/MainPage";
import HelpCenterPage from "./components/pages/HelpCenterPage";
import FAQPage from "./components/pages/FAQPage";
import AboutPage from "./components/pages/AboutPage";
import PublicOfferPage from "./components/pages/PublicOfferPage";
import MainPageAuthorizedUser from './components/pages/MainPageAuthorizedUser'
import Transfer from './components/pages/Transfer'
import PaymentServices from './components/pages/PaymentServices'
import Identify from "./components/pages/Identify";
//components
import NavBar from "./components/ux/NavBar";
//constants
import MODAL_CONTROLS from './constants/modalControls.constants';
import Profile from "./components/ux/modal/Profile";
import { getUser } from "./API-services/user.services";

function App() {
  const [isOpen, setIsOpen] = React.useState(false)
  const [modalController, setModalController] = React.useState('');
  const [user, setUser] = React.useState(null);
  const [[phoneNumber, password, nickname], setAuth] = React.useState([]);
  const [isAuthenticated, setIsAuthenticated] = React.useState(localStorage.getItem("userID"));

  React.useEffect(() => {
    if (localStorage.getItem('userID')) {
      setUser(async () => await getUser())
    }
  });

  return (
    <Router>
      <NavBar setIsOpen={setIsOpen} setModalController={setModalController} />
      <div id='modals'>
        {modalController === MODAL_CONTROLS.registrate && <CreateWallet setAuth={setAuth} callback={setModalController} isOpen={isOpen} setIsOpen={setIsOpen} />}
        {modalController === MODAL_CONTROLS.login && <Welcome setAuth={setAuth} callback={setModalController} isOpen={isOpen} setIsOpen={setIsOpen} />}
        {modalController === MODAL_CONTROLS.smsCode && <SmsCodeEnter nickname={nickname} phone={phoneNumber} password={password} callback={setModalController} isOpen={isOpen} setIsOpen={setIsOpen} />}
        {modalController === MODAL_CONTROLS.profile && <Profile user={user} callback={setModalController} isOpen={isOpen} setIsOpen={setIsOpen} />}
        {modalController === MODAL_CONTROLS.deposite && <Deposite user={user} isOpen={isOpen} setIsOpen={setIsOpen} />}
      </div>
      <Routes>
        <Route path="/" element={(isAuthenticated) ? <MainPageAuthorizedUser /> : <MainPage />} />
        <Route path="/news/:id" element={<NewsPage />} />
        <Route path="/news" element={<MobileNewsPage />} />
        <Route path="/help-center" element={<HelpCenterPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/public-offer" element={<PublicOfferPage />} />
        <Route path="/identify" element={<Identify setModalController={setModalController} setIsOpen={setIsOpen} />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path='/authorized' element={<MainPageAuthorizedUser />} />
        <Route path='/transfer' element={<Transfer />} />
        <Route path='/payment-services' element={<PaymentServices />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App;
