import { useState } from 'react'
import ButtonCloseModal from '../../../ux/Buttons/ButtonCloseModal'
import BankCardIcon from '../../../../images/bank&Card.png'
import ProfileImg from '../../../../images/profile.jpeg'
import Graph1 from '../../../../images/graph1.png'
import Graph2 from '../../../../images/graph2.png'
import ViaBankCard from './ViaBankCard'
import ViaFriend from './ViaFriend'
import VisaCard from '../../VisaCard'
import ButtonBack from '../../Buttons/ButtonBack'
import AddCard from '../AddCard'


function DepositAccount({ openModal }) {


    const [openViaBankCard, setOpenViaBankCard] = useState(false)
    const [openViaFriend, setOpenViaFriend] = useState(false)


    const [openAddCard, setOpenAddCard] = useState(false)
    const toggleAddCard = () => setOpenAddCard(!openAddCard)

    return (
        <>
            <div className="fixed w-full max-w-[1520px] laptop:w-11/12 desktop:w-9/12 top-0 left-0 laptop:top-1/2 laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2 z-20 bg-[#3a383d] laptop:bg-[#245626] flex flex-col laptop:flex-row justify-start laptop:justify-center items-center pt-4 laptop:pt-0 laptop:py-14 laptop:rounded-2xl h-full laptop:max-h-[600px] px-5">
                <div className="hidden laptop:block relative -left-16 top-8 max-w-[400px]">
                    <img className="max-w-[350px] desktop:max-w-[400px]" src={BankCardIcon} alt="BankAndCard" />
                </div>

                <VisaCard className="laptop:hidden left-1 mb-6" />

                <div className="laptop:bg-white w-full max-w-[600px] laptop:max-w-[300px] px-3 pb-14 laptop:pb-0 laptop:py-8 laptop:mt-12 h-fit laptop:h-full rounded-xl">
                    <div className="hidden laptop:block w-28 h-28 mx-auto rounded-full overflow-hidden">
                        <img src={ProfileImg} alt="profile" />
                    </div>
                    <p className="hidden laptop:block text-center mb-8">@aru001</p>

                    <div className="space-y-5 mb-0">
                        <button onClick={() => setOpenViaFriend(true)} className="bg-white text-black font-bold laptop:font-normal laptop:text-white laptop:bg-[#245626] w-full py-7 laptop:py-4 rounded-lg text-lg shadow-[0px_10px_4px_0px_#00000040]">
                            Попросить друга
                        </button>
                        <button onClick={() => setOpenViaBankCard(true)} className="bg-white text-black font-bold laptop:font-normal laptop:text-white laptop:bg-[#245626] w-full py-7 rounded-lg laptop:py-4 text-lg shadow-[0px_10px_4px_0px_#00000040]">
                            Через банковскую карту
                        </button>
                        <button onClick={toggleAddCard} className="bg-white text-black font-bold laptop:font-normal laptop:text-white laptop:bg-[#245626] w-full py-7 rounded-lg laptop:py-4 text-lg shadow-[0px_10px_4px_0px_#00000040]">
                            Добавить банковскую карту
                        </button>
                    </div>
                </div>

                {openViaBankCard ||
                    <div className="hidden laptop:block w-full max-w-[400px] ml-10">
                        <img src={Graph1} alt="graph" className="max-w-[300px] desktop:max-w-[350px]" />
                        <img src={Graph2} alt="graph" className="max-w-[300px]" />
                    </div>
                }

                <ButtonBack onClick={openModal} className="top-10 left-4 min-[375px]:top-10 min-[375px]:left-4 tablet:left-10" />
                <ButtonCloseModal onClick={openModal} position='hidden laptop:block -top-5 -right-5' />
            </div>


            {openViaBankCard && <ViaBankCard openModal={() => setOpenViaBankCard(false)} />}
            {openViaFriend && <ViaFriend openModal={() => setOpenViaFriend(false)} />}
            {openAddCard && openAddCard && <AddCard openModal={toggleAddCard} />}


            <div className={`fixed inset-0 z-10 bg-[#040202A6]`}></div>
        </>
    )
}


export default DepositAccount