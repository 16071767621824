import React from 'react'
import ReactModal from 'react-modal'
import MODAL_CONTROLS from '../../../constants/modalControls.constants';
import PERSONAL_DATA from '../../../constants/personalData.constants';
import PhoneInput from 'react-phone-input-2';
import close from "../../../images/close.svg";

export default function PersonalData({ isOpen, setIsOpen }) {
  const [email, setEmail] = React.useState('');
  const [nickname, setNickname] = React.useState('');
  const [adress, setAdress] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');

  function handleSubmit(event) {
    event.preventDefault();
    console.log('save data');
  }
  function handlePhoneChange(phoneNumber) {
    if (phoneNumber === '') setPhoneNumber('7');
    else setPhoneNumber(phoneNumber);
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      appElement={document.getElementById('modals')}
      style={PERSONAL_DATA.personalStyles}
    >
      <div className=' flex justify-center w-full relative ' onClick={() => setIsOpen(false)}>
        <div className=' absolute right-[32%] top-[5%] cursor-pointer z-40 ' onClick={() => setIsOpen(false)}>
          <img loading="eager" alt='' src={close} className=' ' />
        </div>
        <section onClick={(e) => e.stopPropagation()} className='mt-10 w-1/3 z-30 bg-[--profile_bt_bg] rounded-3xl p-10 '>
          <form className=' grid w-full px-4 text-base-black font-normal' onSubmit={handleSubmit}>
            <section className=''>
              <label className='' >{PERSONAL_DATA.nickname}</label>
              <input className='bg-[--input_bg] rounded-lg px-2 py-1 w-full' type='text' value={nickname} onChange={(e) => setNickname(e.currentTarget.value)} />
            </section>
            <section className=''>
              <label className='' >{PERSONAL_DATA.fullName}</label>
              <input className='bg-[--input_bg] rounded-lg px-2 py-1 w-full text-[gray]' type='text' disabled value={'fullName'} />
            </section>
            <section className=''>
              <label className='' >{PERSONAL_DATA.IIN}</label>
              <input className='bg-[--input_bg] rounded-lg px-2 py-1 w-full text-[gray]' type='text' disabled value={'IIN'} />
            </section>
            <section className=''>
              <label className='' >{PERSONAL_DATA.IDNumber}</label>
              <input className='bg-[--input_bg] rounded-lg px-2 py-1 w-full text-[gray]' type='text' disabled value={'ID number'} />
            </section>
            <section className=''>
              <label className='' >{PERSONAL_DATA.email}</label>
              <input className='bg-[--input_bg] rounded-lg px-2 py-1 w-full' type='email' value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
            </section>
            <section className=''>
              <label className='' >{PERSONAL_DATA.adress}</label>
              <input className='bg-[--input_bg] rounded-lg px-2 py-1 w-full' type='text' value={adress} onChange={(e) => setAdress(e.currentTarget.value)} />
            </section>
            <section className=''>
              <PhoneInput
                name='phoneNumber'
                specialLabel={PERSONAL_DATA.phoneNumber}
                country='kz'
                placeholder='+7 ('
                value={phoneNumber}
                onChange={handlePhoneChange}
                inputClass='bg-[--input_bg] rounded-lg px-2 py-1 w-full'
                disableCountryCode={false}
              />
            </section>
            <section className='flex justify-center my-5 font-bold'>
              <button type="submit" className='bg-[--btn_color] px-2 py-1 rounded-lg'>{PERSONAL_DATA.close}</button>
            </section>
          </form>
        </section>
      </div>
    </ReactModal>
  )
}
