import ButtonBack from '../../ux/Buttons/ButtonBack'
import redSign from '../../../images/redSign.png'
import ButtonCloseModal from '../../ux/Buttons/ButtonCloseModal'


function RefusalService({ openModal }) {

    return (
        <>
            <div className="fixed top-0 w-full h-full laptop:h-auto laptop:max-w-[550px] laptop:top-1/2 laptop:bottom-auto laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2 z-30 laptop:rounded-3xl bg-[#2A2A2A]">
                <form className="relative w-full h-full flex flex-col justify-evenly max-h-[700px] laptop:h-auto py-8 px-5 pt-20 laptop:pt-6 laptop:px-10 overflow-hidden">

                    <p className="text-3xl text-end laptop:text-start laptop:text-4xl font-medium laptop:font-thin laptop:max-w-[400px] text-white laptop:mb-3">Отказаться от обслуживания</p>

                    <p className='mb-2 text-white text-lg'>
                        Причина
                    </p>
                    <textarea rows="5" className="block w-full pt-5 bg-[#F1F9F17A] resize-none mb-5 rounded-lg">
                    </textarea>

                    <div className="flex items-start text-white justify-between gap-1 mb-6">
                        <img src={redSign} alt="redSign" className='relative -left-1' />
                        <div className="font-thin text[#F7F3FD]">
                            <span className="mb-2 block">*Уважаемый клиент,</span>
                            <span className="block">Настоящим уведомляем вас о том, что в случае отказа от дальнейшего обслуживания по нашему продукту/услуге, доступ к средствам будет прекращен.</span>
                        </div>
                    </div>

                    <button className="block mx-auto px-16 pt-2 pb-3 text-white bg-[#EE2020] rounded-[10px]">
                        Отказаться
                    </button>

                    <ButtonCloseModal onClick={openModal} position="-top-12 -right-12"/>

                    <ButtonBack className={'top-5 left-3'} onClick={openModal} />

                </form>
            </div>


            <div className={`fixed inset-0 z-10 bg-[#040202A6]`}></div>
        </>
    )
}


export default RefusalService