import ButtonCloseModal from '../../Buttons/ButtonCloseModal'
import VisaCard from '../../VisaCard'
import ActiveTransactions from '../../Transactions/ActiveTransactions'
import ButtonBack from '../../Buttons/ButtonBack'


function ActiveOperations({ openModal }) {

    return (
        <>
            <div className="fixed top-0 left-0 w-full laptop:w-fit laptop:top-1/2 laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2  flex justify-evenly items-center h-full max-h-[600px] laptop:rounded-2xl z-20">

                <div className="bg-[#3a383d] laptop:bg-[#D4D4D4] w-full laptop:w-[1000px] desktop:w-[1150px] px-5 py-5 laptop:py-8 h-full laptop:rounded-xl flex flex-col laptop:flex-row">
                    <VisaCard className={'mx-auto laptop:self-center relative desktop:-left-28 rounded-[10px]'} />

                    <ActiveTransactions className='max-w-[550px] mx-auto' />
                </div>

                <ButtonBack className={'top-8 left-4'} onClick={openModal} />
                <ButtonCloseModal onClick={openModal} position='hidden laptop:block -top-5 -right-5' />

            </div>
        </>
    )
}


export default ActiveOperations