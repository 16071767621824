import BackIcon from '../../../images/back.png'


function ButtonBack({ className, onClick }) {
    return (
        <button onClick={onClick} className={`absolute laptop:hidden w-10 h-10 rounded-full bg-[#CCCCCC] ${className}`}>
            <img src={BackIcon} alt="png" />
        </button>
    )
}

export default ButtonBack