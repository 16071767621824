import { Link } from "react-router-dom";

function CardOperations() {
    return (
        <div className="grid grid-cols-2 w-full gap-3 mt-4 laptop:mt-0 laptop:gap-x-5 laptop:gap-y-6 *:shadow-[0px_10px_4px_0px_#00000040] *:laptop:shadow-[10px_10px_4px_0px_#00000040]">
            <Link to="#" className="text-center py-4 laptop:py-6 desktop:py-10 rounded-lg text-lg laptop:text-xl laptop:font-bold text-white bg-[#3A383D] laptop:bg-[#204D22]">Пополнить</Link>
            <Link to="#" className="text-center py-4 laptop:py-6 desktop:py-10 rounded-lg text-lg laptop:text-xl laptop:font-bold text-white bg-[#3A383D] laptop:bg-[#204D22]">Перевести</Link>
            <Link to="#" className="text-center py-4 laptop:py-6 desktop:py-10 rounded-lg text-lg laptop:text-xl laptop:font-bold text-white bg-[#3A383D] laptop:bg-[#204D22]">Платежи</Link>
            <Link to="#" className="text-center py-4 laptop:py-6 desktop:py-10 rounded-lg text-lg laptop:text-xl laptop:font-bold text-white bg-[#3A383D] laptop:bg-[#204D22]">Новости</Link>
        </div>
    )
}



export default CardOperations