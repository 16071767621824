import { useState } from 'react'
import RecentTransactions from "./RecentTransactions";
import ActiveTransactions from './ActiveTransactions';
import ArrowDownSvg from '../../../images/arrowDown.svg'


export default function Transactions() {

    const [expand, setExpand] = useState(false)

    return (
        <section className="relative z-10 transactions mt-4 mb-6 laptop:mt-10 laptop:mb-20">
            <div className={`flex justify-center gap-x-32 laptop:bg-[#606060] laptop:w-11/12 mx-auto laptop:px-10 pt-4 pb-6 ${expand ? 'laptop:h-fit' : 'laptop:h-[418px]'} overflow-hidden  rounded-xl transition-all`}>

                <RecentTransactions />
                <ActiveTransactions className="hidden laptop:block" />
            </div>

            <button className={`hidden ${expand ? 'laptop:none' : 'laptop:flex'} absolute z-20 justify-center items-center py-3 w-full max-w-[600px] -bottom-7 left-1/2 -translate-x-1/2 bg-[#FFDD2D] rounded-2xl text-lg`} onClick={() => setExpand(!expand)}>
                <p>Развернуть</p>
                <img src={ArrowDownSvg} alt="arrowDown" className="absolute -bottom-4" />
            </button>
        </section>
    )
}
