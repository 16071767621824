import { Link } from "react-router-dom";
import BellIcon from '../../../images/bellIcon.png'
import ConvertIcon from '../../../images/convertIcon.png'

function ActiveTransactions({ className="" }) {
    return (
        <div className={`relative w-full ${className}`}>
            <p className="py-2 laptop:py-4 laptop:mb-2 text-center rounded-[10px] laptop:*:shadow-[10px_10px_4px_0px_#00000040] text-white laptop:font-light text-lg bg-[hsla(0,0%,80%,.1)] laptop:bg-[#204D22]">Активные запросы</p>

            <div className="mt-3 laptop:mt-2 laptop:w-11/12 mx-auto space-y-[16px]">
                <div className='relative flex justify-between items-center px-6 py-3 laptop:py-2 bg-[#AFC5B0] rounded-[10px] text-base leading-5 laptop:leading-6 laptop:text-[17px] font-thin'>
                    <img className="absolute w-10 top-1/2 -left-[20px] -translate-y-1/2" src={BellIcon} alt="Bell" />
                    <img className="absolute w-7 -bottom-2 -right-2" src={ConvertIcon} alt="convertIcon" />
                    <p>
                        <span className="text-red-500">@aru01</span> просит тебя о помощи! У него недостаточно средств на кошельке. Хочешь помочь ему <Link to="#" className="text-red-500 relative after:absolute after:left-0 after:right-0 after:h-[.09px] after:bg-red-500 after:bottom-[2px]">пополнить</Link> баланс?
                    </p>
                </div>

                

            </div>
        </div>
    )
}

export default ActiveTransactions