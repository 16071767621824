const DEPOSITE = {
    askFriend: 'Попросить друга',
    bankCard: 'Банковская карта',
    cardNumber: 'Номер карты',
    nickname: 'Никнейм',
    comment: 'Коментарий',
    customStyles: {
        content: {
            border: 0,
            background: 'none',
        },
        overlay: {
            background: 'none',
        }
    }
}

export default DEPOSITE;