function HistoryItem({operationType, sum}) {
    return (
        <div className={`w-full flex justify-between items-center px-4 laptop:px-7 py-3 ${operationType ? 'bg-[#ccdfcd]' : 'bg-[#FFB42E]'} rounded-[10px] text-lg`}>
            <div className=''>
                <p className="bank text-xl">Forte Bank</p>
                <p className="type text-base">Перевод</p>
            </div>
            <span className='font-bold text-[red] text-lg'>{operationType ? '+' : '-'}{sum}$</span>
        </div>
    )
}

export default HistoryItem