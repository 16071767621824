import { API, AUTH, EDIT_EMAIL, EDIT_USER, REGISTER, SMS_CODE, USER_ID, PHONE, CHANGE_PASSWORD, ADD_CARD, TOP_UP_BALANCE, CREATE_TRANSACTION, GET_ALL_NOTIFICATIONS } from "./API";

const headers = new Headers();
headers.append("Content-Type", "application/json");

// function setCookies(response) {
//     const expireDate = Date.now()+7*24*60*60*1000;
//     document.cookie = `${USER}=${response.user_id}`;
//     document.cookie = `${TOKEN}=${response.user_token}`;
//     document.cookie = `expires=${new Date(expireDate)}`;
//     document.cookie = `path=/`;
// }

// function getFromCookie(subString) {
//     const cookies = document.cookie.split(';');
//     for (let i = 0; i < cookies.length; i++) {
//         const cookie = cookies[i].trim();
//         if (cookie.startsWith(`${subString}=`)) {
//             return cookie.substring(`${subString}=`.length);
//         }
//     }
//     return null;
// }

export const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    localStorage.removeItem("cardBankData")
};

export const login = async (phone, password) => {
    phone = "+" + phone;
    try {
        const response = await fetch(`${API}${AUTH}`, {
            method: "POST",
            headers,
            body: JSON.stringify({ phone, password }),
        });
        const data = await response.json();
        if (data.success) {
            localStorage.setItem("token", JSON.stringify(data.user_token));
            localStorage.setItem("userID", JSON.stringify(data.user_id));
            window.location.reload();
        }
        return data;
    } catch (e) {
        console.log(e)
    }
};

export const getUser = async () => {
    try {
        const response = await fetch(
            `${API}${USER_ID}${localStorage.getItem("userID")}`,
            {
                method: "GET",
                headers: {
                    ...headers,
                    authorization: `Bearer ${JSON.parse(
                        localStorage.getItem("token")
                    )}`.toString(),
                },
            }
        );
        const data = await response.json();
        if (data.success) {
            return data.user;
        }
        return data.message;
    } catch (e) { }
};

export const register = async (nickname, phone, password) => {
    phone = "+" + phone;
    try {
        const response = await fetch(`${API}${REGISTER}`, {
            method: "POST",
            headers,
            body: JSON.stringify({ nickname, phone, password }),
        });
        const data = await response.json();
        if (data.success) {
            localStorage.setItem("token", JSON.stringify(data.user_token));
            localStorage.setItem("userID", JSON.stringify(data.user_id));
            window.location.reload();
        }
        return response.message;
    } catch (e) { }
};

// export const modifyPnoneNumber = async (phone) => { // TODO wait for backend
//     phone = '+'+phone;
//     try {
//         const response = await fetch(`${API}${PHONE}`, {
//             method: 'POST',
//             headers,
//             body: JSON.stringify({phone}),
//         })
//         const data = await response.json();
//         return data.message;
//     } catch (e) {
//     }
// }

export const modifyUser = async (nickname, fullname, address) => {
    const user = { id: localStorage.getItem("userID") };
    if (nickname) user.nickname = nickname;
    if (fullname) user.fullname = fullname;
    if (address) user.address = address;
    try {
        const response = await fetch(`${API}${EDIT_USER}`, {
            method: "POST",
            headers,
            body: JSON.stringify(user),
        });
        const data = await response.json();
        if (data.success) return data.user;
        return data.message;
    } catch (e) { }
};

export const modifyUserEmail = async (email) => {
    try {
        const response = await fetch(`${API}${EDIT_EMAIL}`, {
            method: "POST",
            headers,
            body: JSON.stringify({ email, id: localStorage.getItem("user_id") }),
        });
        const data = await response.json();
        if (data.success) return data.user;
        return data.message;
    } catch (e) { }
};

// export const sendSMSCode = async (smsCode) => { // TODO wait for backend
//     try {
//         const response = await fetch(`${API}${SMS_CODE}`, {
//             method: 'POST',
//             headers,
//             body: JSON.stringify({smsCode}),
//         })
//         const data = await response.json();
//         return data.message;
//     } catch (e) {
//     }
// }

export const changeUserPassword = async (payload) => {
    try {
        const response = await fetch(`${API}${CHANGE_PASSWORD}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
            },
            // body: JSON.stringify({ payload, id: localStorage.getItem("user_id") }),
            body: JSON.stringify({ id: localStorage.getItem("userID"), ...payload }),
        });

        return await response.json();
    } catch (e) { }
}

export const addCard = async ({ bank_number, owner, exp_date }) => {
    try {
        const response = await fetch(`${API}${ADD_CARD}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(
                    localStorage.getItem("token"))}`,
            },
            body: JSON.stringify({ user_id: localStorage.getItem("userID"), bank_number, owner, exp_date }),
        });

        const data = await response.json();
        if (data.success) {
            localStorage.setItem("cardBankData", JSON.stringify(data.cardBankData));
        }
        return data
    } catch (e) { }
}

// const topUpBalance = 


export const createTransaction = async (payload) => {
    try {
        const response = await fetch(`${API}${CREATE_TRANSACTION}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(
                    localStorage.getItem("token")
                )}`,
            },
            // body: JSON.stringify({ payload, id: localStorage.getItem("user_id") }),
            body: JSON.stringify({ ...payload }),
        });

        return await response.json();
    } catch (e) { }
}


export const getAllNotifications = async () => {
    try {
        const response = await fetch(`${API}${GET_ALL_NOTIFICATIONS}${localStorage.getItem("userID")}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${JSON.parse(
                    localStorage.getItem("token")
                )}`,
            }
        })
    } catch (e) { }
}