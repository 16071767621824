import { useState, useEffect } from 'react'
import Banner from '../../images/banner.png'
import VisaCard from '../ux/VisaCard'
import ChartMobile from '../ux/Charts/ChartMobile'
import CardOperations from '../ux/CardOperations'
import Transactions from '../ux/Transactions'
import PaymentSearch from '../ux/PaymentSearch'
import Charts from '../ux/Charts'
import ChangePassword from '../ux/modal/ChangePassword'
import ContactsData from '../ux/modal/ContactsData'
import RefusalService from '../ux/modal/RefusalService'
import DepositAccount from '../ux/modal/DepositAccount.jsx'

function MainPageAuthorizedUser() {

    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)
    const [openContactsDataModal, setOpenContactsDataModal] = useState(false)
    const [openRefusalService, setOpenRefusalService] = useState(false)
    const [openDeposit, setOpenDeposit] = useState(false)


    const toggleChangePasswordModal = () => setOpenChangePasswordModal(!openChangePasswordModal)
    const toggleContactsDataModal = () => setOpenContactsDataModal(!openContactsDataModal)
    const toggleRefusalService = () => setOpenRefusalService(!openRefusalService)
    const toggleDeposit = () => setOpenDeposit(!openDeposit)



    return (
        <>
            <div id="AuthorizedUser" className="mx-4 mt-2 relative tablet:max-w-[640px] tablet:mx-auto laptop:max-w-[1560px]">
                <img className="hidden laptop:block object-bottom rounded-[10px] h-[300px] w-10/12 mt-2 mx-auto" src={Banner} alt="banner" />

                <section className="visa w-full laptop:w-11/12 desktop:w-9/12 mx-auto mt-4 laptop:mt-10 flex flex-col laptop:flex-row items-center justify-center gap-2 laptop:gap-12 laptop:h-[300px]">
                    <VisaCard />
                    <ChartMobile className="mt-4 laptop:hidden" isAuthorizedMain={true} />
                    <CardOperations openDeposit={toggleDeposit} />
                </section>

                <Transactions />
                <PaymentSearch className="hidden laptop:block" />
                {/* <Charts className="hidden laptop:block" /> */}


                {/* <ButtonBack className="top-2 left-2 min-[375px]:top-4 min-[375px]:left-4" /> */}

            </div>

            {
                openChangePasswordModal &&
                <ChangePassword
                    openModal={toggleChangePasswordModal}
                    nextModelOpen={toggleContactsDataModal} />
            }

            {
                openContactsDataModal && <ContactsData openModal={toggleContactsDataModal} />
            }


            {
                openRefusalService && <RefusalService openModal={toggleRefusalService} />
            }


            {
                openDeposit && <DepositAccount openModal={toggleDeposit} />
            }

        </>
    )
}

export default MainPageAuthorizedUser