import React from "react";
import HelpCenterInfo from "../ux/HelpCenterInfo";
import about from "../../images/aboutUsImage.png";
import PrevButton from "../ux/PrevButton";

const AboutPage = () => {
  return (
    <>
      <div className="mobile:hidden laptop:block w-[80%] mx-auto font-monserrat leading-10 desktop2:text-xl desktop2:leading-10">
        <HelpCenterInfo title={"О нас"} />
        <p className="text-justify mt-14">
          Мы — инновационная компания, основанная с целью упростить и
          усовершенствовать способы управления финансами. Наш электронный
          кошелек представляет собой надежный инструмент для проведения быстрых
          и безопасных транзакций в любой точке мира. Мы стремимся к тому, чтобы
          наши клиенты могли легко управлять своими финансами, используя
          передовые технологии и максимально удобные интерфейсы.
        </p>
        <img loading="eager" src={about} alt="about" className=" w-full object-cover my-10" />
        <p className="mb-5">Наши суперспособности:</p>
        <ul className="list-disc ml-5">
          <li>
            <b>Функциональность:</b> Наш электронный кошелек предоставляет
            широкий спектр функций, включая переводы между пользователями,
            оплату товаров и услуг, пополнение счета и многое другое.
          </li>
          <li>
            <b>Глобальная доступность:</b> Мы обеспечиваем возможность
            проведения транзакций и доступ к финансовым средствам в любой точке
            земного шара, обеспечивая удобство и безопасность наших клиентов.
          </li>
          <li>
            <b>Безопасность:</b> Мы придаем высокое значение безопасности и
            конфиденциальности данных наших пользователей. Все операции защищены
            современными технологиями шифрования и многоуровневыми мерами
            защиты.
          </li>
          <li>
            <b>Поддержка клиентов:</b> Наша команда предоставляет
            высококачественную поддержку клиентам, готовую помочь в любое время
            суток.
          </li>
        </ul>
        <p className="text-justify mt-5">
          Мы гордимся тем, что наш электронный кошелек не только удобен в
          использовании, но и способствует финансовой инклюзии, обеспечивая
          доступ к финансовым услугам для всех слоев населения. Присоединяйтесь
          к нам и переходите на новый уровень управления своими финансами!
        </p>
      </div>
      <div className="mobile:block laptop:hidden bg-[#2D2D35] h-[100vh] w-full font-monserrat">
        <div className="w-[90%] mx-auto pt-5 text-white">
          <PrevButton />
          <p className="text-2xl font-medium text-center my-5">О нас</p>
          <p className="text-justify">
            Lorem ipsum dolor sit amet consectetur. Sagittis turpis viverra ut
            venenatis tincidunt egestas et at placerat. Lectus amet neque augue
            magna vitae tellus feugiat blandit. In rhoncus nisl tellus pretium
            posuere at. Aliquam enim est augue nunc vitae et. Mi viverra
            penatibus elit senectus. Iaculis enim integer turpis porttitor
            molestie nunc.Lorem ipsum dolor sit amet consectetur. Sagittis
            turpis viverra ut venenatis tincidunt egestas et at placerat. Lectus
            amet neque augue magna vitae tellus feugiat blandit. In rhoncus nisl
            tellus pretium posuere at. Aliquam enim est augue nunc vitae et. Mi
            viverra penatibus elit senectus. Iaculis enim integer turpis
            porttitor molestie nunc.tellus feugiat blandit. In rhoncus nisl
            tellus pretium posuere at. Aliquam enim est augue nunc vitae et. Mi
            viverra penatibus elit senectus. Iaculis enim integer turpis
            porttitor molestie nunc.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
