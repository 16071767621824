import { useState } from 'react'
import PaymentSearch from "../ux/PaymentSearch"
import MyPaymentsTransactions from "../ux/Transactions/MyPaymentsTransactions"
import ButtonBack from "../ux/Buttons/ButtonBack"
import VisaCard from "../ux/VisaCard"
import ProfileIcon from '../../images/profile.jpeg'
import { useNavigate } from 'react-router-dom'

function PaymentServices() {
    const [isProfileHistory, setIsProfileHistory] = useState(false)

    const toggleProfile = (toggleState) => setIsProfileHistory(toggleState === 3)

    const navigate = useNavigate()

    return (
        <div id="paymentsPage" className="mx-4 mt-2 relative tablet:max-w-[640px] tablet:mx-auto laptop:max-w-[1560px]">
            <PaymentSearch />

            {
                isProfileHistory && <section className="profile laptop:hidden pt-4 ps-1 flex items-center gap-5">
                    <div className="rounded-full w-20 h-20 overflow-hidden">
                        <img src={ProfileIcon} alt="profile" /></div>
                    <p className="mt-2 text-white text-2xl">Уважаемая, <span className="name">Арай</span></p>
                </section>
            }


            <VisaCard className="laptop:hidden mt-3 mb-1 mx-auto" />

            <MyPaymentsTransactions toggleProfile={toggleProfile} />

            <ButtonBack className="top-1 -left-1" onClick={() => navigate('/')} />

        </div>
    )
}

export default PaymentServices