import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import close from "../../../images/close.svg";

const HelpCenter = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      {isOpen && (
        <div
          className="w-full  h-full bg-black bg-opacity-60 fixed flex justify-center items-center z-10 font-monserrat"
          onClick={() => setIsOpen(false)}
        >
          <div className="relative z-30 bg-white py-12 px-14 rounded-2xl" onClick={(e) => e.stopPropagation()} >
            <img loading="eager"
              src={close}
              alt="close"
              className="absolute top-[-14%] right-[-11%] cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
            <p className="text-3xl desktop2:text-5xl text-[#000000]"> Справочный центр </p>
            <div className="flex flex-col desktop2:mt-5">
              {[
                { route: "/about-us", name: "О нас" },
                { route: "/public-offer", name: "Публичная оферта" },
                { route: "/faq", name: "FAQ" },
              ].map((el) => (
                <button
                  key={el.name}
                  className="bg-[#7FAD81] mt-4 p-5 rounded-xl shadow-[1px_2px_5px_1px_#9a9a9a] text-white desktop2:text-3xl hover:bg-[#7FCD81] transition-colors duration-300"
                  onClick={() => navigate({ pathname: `${el.route}` })}
                >
                  {el.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpCenter;
