import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SliderNews = ({ news, isVertical, isMobile }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeId, setActiveId] = useState(1);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleSlideChange = (swiper) => {
    setActiveId(news[swiper.realIndex].id);

    if (!location.pathname.includes(`/news/${news[swiper.realIndex].id}`) && isVertical && !isMobile) {
      navigate(`/news/${news[swiper.realIndex].id}`);
    }
  };


  return (
    <div
      className={`my-20 relative font-monserrat ${isVertical ? "laptop:w-[65%] desktop:w-[55%] h-[70vh]" : " h-[40vw] tablet:h-auto laptop:w-[70%] desktop:w-[60%]"}`} >
      {news.length > 0 && (
        <Swiper
          onSwiper={setSwiperInstance}
          onSlideChange={handleSlideChange}
          spaceBetween={10}
          slidesPerView={3}
          centeredSlides={true}
          loop={true}
          direction={isVertical ? "vertical" : "horizontal"}
          style={{ height: "100%" }}
        >
          {news.map((el) => (
            <SwiperSlide key={el.id}>
              <img loading="eager"
                src={`https://api.open-trade.garcom.kz/wallet/production/v1/news/preview/${el.image_url}`}
                alt={`Slide ${el.id}`}
                className={`w-[100%] h-full object-cover aspect-[8/9] rounded-xl transition-all duration-500 cursor-pointer ${activeId === el.id
                  ? "opacity-100 blur-none"
                  : "opacity-50 blur-sm"
                  }`}
                onClick={() => navigate({ pathname: `/news/${el.id}` })}
              />
              <div
                className={`bg-white p-1 absolute bottom-[5%] w-full rounded-xl text-sm desktop2:text-lg ${isVertical ? "line-clamp-2" : "line-clamp-2"
                  } ${activeId === el.id
                    ? "opacity-100 blur-none"
                    : "opacity-50 blur-sm"
                  }`}
              >
                <p>{el.title}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      {!isMobile && (
        <>
          {" "}
          <div
            className={`custom-prev text-2xl absolute ${isVertical
              ? "left-[45%] rotate-[90deg] top-[-50px]"
              : "left-0 bottom-[-60px]"
              } px-4 rounded-full cursor-pointer hover:bg-gray-300 z-10`}
            onClick={() => swiperInstance.slidePrev()}
          >
            &#10094;
          </div>
          <div
            className={`custom-next text-2xl absolute ${isVertical
              ? "left-[45%] rotate-[90deg] bottom-[-50px]"
              : "right-0 bottom-[-60px]"
              }  px-4 rounded-full cursor-pointer hover:bg-gray-300 z-10`}
            onClick={() => swiperInstance.slideNext()}
          >
            &#10095;
          </div>
        </>
      )}
    </div>
  );
};

export default SliderNews;
