import { Link } from "react-router-dom";
import { useState } from 'react'
import SearchIcon from '../../images/searchIcon.svg'


const payments = [
    { name: "Игры", link: "#" },
    { name: "Мобильная связь", link: "#" },
    { name: "Перевод на карту", link: "#" },
    { name: "Финансы", link: "#" },
    { name: "Транспорт", link: "#" },
    { name: "Ком.услуги + интернет", link: "#" },
    { name: "Социальные сети", link: "#" },
    { name: "Обьявления и хостинг", link: "#" },
    { name: "Охрана", link: "#" },
    { name: "Подарочные карты", link: "#" },
    { name: "Путешествие", link: "#" },
    { name: "Другое", link: "#" }
]

function PaymentSearch({ className }) {

    const [searchItem, setSearchItem] = useState('')
    const [filteredNames, setFilteredNames] = useState(payments)

    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)


        const filteredItemNames = payments.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredNames(filteredItemNames);
    }

    return (
        <section className={`hidden laptop:block paymentSearch mt-10 mb-10 mx-auto ${className}`}>
            <div className="w-5/6 py-10 px-10 desktop:px-28 justify-center min-h-[400px] rounded-2xl bg-[#204D22] mx-auto">
                <div className="relative w-full">
                    <input type="text" className="block w-full h-20 px-6 text-2xl rounded-[10px] border-none outline-none" placeholder="Поиск платежей"
                        value={searchItem} onChange={handleInputChange} />

                    <img src={SearchIcon} alt="search" className="absolute top-1/2 -translate-y-1/2 right-6" />
                </div>

                <div className="paymentGrid grid auto-rows-1 grid-cols-6 h-fit gap-x-4 gap-y-5 mt-10">
                    {
                        filteredNames.map(
                            item => <Link key={item.name} to={item.link} className="item bg-white p-4 rounded-xl hover:opacity-75">{item.name}</Link>
                        )
                    }
                </div>

            </div>
        </section>
    )
}

export default PaymentSearch