import React, { useEffect, useState } from "react";
import PrevButton from "../ux/PrevButton";
import SliderNews from "../ux/SliderNews";
import { Link } from "react-router-dom";

const MobileNewsPage = () => {
  const [news, setNews] = useState([]);

  const getNewsInfo = async () => {
    try {
      const response = await (await fetch("https://api.open-trade.garcom.kz/wallet/production/v1/news")).json();
      setNews(response.news);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNewsInfo();
  }, [])

  return (
    <div className="laptop:hidden bg-[#2D2D35] h-full">
      <div className="w-[600px] max-w-[90%] mx-auto py-5">
        <Link to="/">
          <PrevButton />
        </Link>
        <p className="text-[#FFFFFF] text-4xl mt-5 mb-[-40px]">Новости</p>
        <SliderNews news={news} isVertical={true} isMobile={true} />
      </div>
    </div>
  );
};

export default MobileNewsPage;
