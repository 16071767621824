const IDENTIFY = {
    title: 'Управляйте своими финансами на новом уровне',
    simple: 'Упрощенная',
    simpled: 'Упрощенно',
    idetify: 'идентификация',
    idetified: 'идентифицированный',
    idetifiedCapitalize: 'Идентифицированный',
    unidetifeid: 'Неидентифицированный',
    full: 'Полная',
    identifyKnowledge: 'Узнать об опциях идентификации',
    wallet: 'кошелек',
    idetifyTextList: [
        'Пополнение кошелька и оплата за услуги с баланса кошелька в рамках лимитов',
        'Переводы и платежи на идент. пользователей WMK',
        'Перевод WMK другим неидент. пользователям системы WebMoney',
        'Вывод средств с кошелька WMK на банковскую карту',
        'Максимальная безопасность',
        [
            'Максимальная безопасностьПрием переводов и платежей от других пользователей WMK.',
            'Максимальная сумма разовой операции по WMK - до 172 000 тенге',
            'Максимальный оборот по всем операциям в сутки - до 344 000 тенге',
            'Максимальная сумма на балансе WMK-кошелька - до 344 000 тенге',
        ],
    ],
    passIdentification: 'Пройти идентификацию',
}

export default IDENTIFY;