import CREATE_WALLET from '../../../constants/createWallet.constants';
import React from 'react';
import ReactModal from 'react-modal';
import PhoneInput from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';
import MODAL_CONTROLS from '../../../constants/modalControls.constants';
import { phoneValidate } from '../../../utilits/utilits';
import { register } from '../../../API-services/user.services';
import EyeSvg from '../../../images/createWallet/Eye-svg.jsx';
import EyeSlashSvg from '../../../images/createWallet/Eye-slash-svg.jsx';
import { Link } from 'react-router-dom';

export default function CreateWallet({ isOpen, setIsOpen, callback }) {
  const [phoneNumber, setPhoneNumber] = React.useState('7');
  const [password, setPassword] = React.useState('');
  const [nickname, setNickname] = React.useState('');
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false)

  function handleSubmit(event) {
    event.preventDefault();
    setIsOpen(false);
    callback(MODAL_CONTROLS.smsCode);
    register(nickname, phoneNumber, password);
  }
  function handlePhoneChange(phoneNumber) {
    if (phoneNumber === '') setPhoneNumber('7');
    else setPhoneNumber(phoneNumber);
  }

  return (
    <ReactModal
      style={CREATE_WALLET.customStyles}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      appElement={document.getElementById('modal')}
    >
      <div className="hidden laptop:flex flex-col gap-6 justify-center items-center text-base font-bold ">
        <h2 className="text-3xl font-light text-center">
          {CREATE_WALLET.createWallet}
        </h2>
        <form className='grid w-full px-10' onSubmit={handleSubmit}>
          <section className='grid w-full'>
            <label name='nickname'>{CREATE_WALLET.nickname}</label>
            <input name='nickname' type="text" value={nickname} onChange={(event) => setNickname(event.currentTarget.value)} className='bg-[--input_bg] rounded-xl py-2 px-2 outline-none' />
          </section>
          <section className='grid w-full'>
            <PhoneInput
              localization={ru}
              name='phoneNumber'
              specialLabel={CREATE_WALLET.phoneNumber}
              country='kz'
              placeholder='+7'
              value={phoneNumber}
              onChange={handlePhoneChange}
              inputClass='bg-[--input_bg] rounded-xl px-2 py-2 w-full outline-none'
              disableCountryCode={false}
            />
          </section>
          <section className='grid w-full relative '>
            <label name='password' tabIndex={-1}>{CREATE_WALLET.password}</label>
            <input
              name='password'
              value={password}
              onChange={(event) => setPassword(event.currentTarget.value)}
              type={isPasswordShown ? "text" : "password"}
              className=' bg-[--input_bg] rounded-xl py-2 px-2 outline-none'
            />
            <div className='absolute right-3 inset-y-8 cursor-pointer' tabIndex={-1} onClick={() => setIsPasswordShown(!isPasswordShown)}>
              {isPasswordShown && <EyeSlashSvg />}
              {!isPasswordShown && <EyeSvg />}
            </div>
          </section>
          <section className='flex gap-3 justify-around'>
            <input type='checkbox' value={isChecked} onChange={() => setIsChecked(!isChecked)} />
            <label className='font-normal '>
              <span>{CREATE_WALLET.agreement}</span>
              <Link to='/public-offer' onClick={() => setIsOpen(false)}>{CREATE_WALLET.offert}</Link>
            </label>
          </section>
          <section className='grid place-items-center'>
            <button
              disabled={!(phoneValidate(phoneNumber) && !!nickname && !!password && isChecked)}
              type='submit'
              className='bg-[--btn_color] text-[--black] font-normal py-2 px-8 rounded-xl mt-5'
            >
              {CREATE_WALLET.create}
            </button>
          </section>
        </form>
      </div>
    </ReactModal>
  );
};

