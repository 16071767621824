import React from 'react'
import ReactModal from 'react-modal'
import MODAL_CONTROLS from '../../../constants/modalControls.constants';
import PERSONAL_DATA from '../../../constants/personalData.constants';
import { useNavigate } from 'react-router-dom';
import cardIcon from '../../../images/personalData/cardIcon.png';
import cardName from '../../../images/personalData/cardName.png';
import chip from '../../../images/personalData/chip.png';
import avatar from '../../../images/personalData/avatar.png';
import HelpCenter from './HelpCenter';
import PersonalData from './PersonalData';
import close from "../../../images/close.svg";
import Support from './Support';


const styles = {
    active: ' p-10 pt-0 flex w-full justify-center gap-28 bg-none ',
    notActive: ' blur-sm p-10 pt-0 flex w-full justify-start gap-0 bg-none rotated__parrent_profile relative h-2/3 border-0 ',
    activeDiv: ' grid gap-40 border-2 min-w-[400px] max-w-[550px] h-[600px] bg-[--white] border-2 rounded-3xl ',
    notActiveDiv: ' grid gap-40 border-2 1/3min-w-[400px] max-w-[550px] h-[600px] bg-[--white] border-0 rounded-3xl rotated_profile ',
    special: 'absolute left-[200px] '
}
export default function Profile({ isOpen, setIsOpen, callback }) {
    const user = {};
    const navigate = useNavigate();
    const [isSubModalOpen, setIsSubModalOpen] = React.useState(false);
    const [modalController, setSubModalController] = React.useState('')
    function handleClick(modalControl) {
        setIsSubModalOpen(true)
        setSubModalController(modalControl)
    }

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                appElement={document.getElementById('modals')}
                style={PERSONAL_DATA.customStyles}
            >
                <div className={isSubModalOpen ? styles.notActive : styles.active}>
                    <div className='absolute right-0 cursor-pointer z-10' onClick={() => setIsOpen(false)}>
                        <img loading="eager" alt='' src={close} className=' ' />
                    </div>
                    <section className={isSubModalOpen ? styles.notActiveDiv : styles.activeDiv}>
                        <div className='mx-25 flex flex-col gap-16 mt-14'>
                            <section className=' flex flex-col '>
                                <div className='flex justify-center rounded-full ' >
                                    <img loading="eager" alt='' src={user.avatar || avatar} className='max-w-40' />
                                </div>
                                <div className='flex justify-center' >{user.fullname || 'ФИО'}</div>
                                <div className='flex justify-center' >@{user.nickname || 'nickname'}</div>
                            </section>
                            <section className=' px-10 grid gap-3 text-[--white]'>
                                <button onClick={() => handleClick(MODAL_CONTROLS.modifyPersonalData)}
                                    className='shadow-inner shadow-gray-900 bg-[--profile_bt_bg] w-full py-2 rounded-2xl'
                                >
                                    {PERSONAL_DATA.personalData}
                                </button>
                                <button onClick={() => { navigate({ pathname: `/identify` }); setIsOpen(false) }}
                                    className='shadow-inner shadow-gray-900 bg-[--profile_bt_bg] w-full py-2 rounded-2xl'
                                >
                                    {PERSONAL_DATA.identyfiInformation}
                                </button>
                                <button onClick={() => handleClick(MODAL_CONTROLS.support)}
                                    className='shadow-inner shadow-gray-900 bg-[--profile_bt_bg] w-full py-2 rounded-2xl'
                                >
                                    {PERSONAL_DATA.support}
                                </button>
                                <button onClick={() => handleClick(MODAL_CONTROLS.help)}
                                    className='shadow-inner shadow-gray-900 bg-[--profile_bt_bg] w-full py-2 rounded-2xl'
                                >
                                    {PERSONAL_DATA.help}
                                </button>
                            </section>
                        </div>
                    </section>
                    <section className={isSubModalOpen ? styles.notActiveDiv + styles.special : styles.activeDiv}>
                        <div className='mx-25 grid'>
                            <section>
                                <div className='relative bg-[--profile_card_bg] rounded-xl text-white mx-5 mt-14 h-[218px] grid items-end'>
                                    <div className='absolute grid grid-cols-2 pr-3 pl-6 pt-1 pb-3 h-full w-full'>
                                        <img loading="eager" alt='' src={cardName} className='h-fit mt-4' />
                                        <div></div>
                                        <img loading="eager" alt='' src={cardIcon} className='absolute w-[70%] top-2 right-1' />
                                        <div></div>
                                        <div className='grid place-items-end'>
                                            <img loading="eager" src={chip} alt='' className='w-1/3 h-fit' />
                                        </div>
                                    </div>
                                    <div className='p-5'>
                                        <div id='card_number'>{user.prefix || 4444} **** **** {user.prefix || 4444}</div>
                                        <div >{PERSONAL_DATA.currentBallance}</div>
                                        <div className='text-[#0AF42F]'>{
                                            `${Intl.NumberFormat('de-DE', { style: 'currency', currency: 'KZT' }).format(user.kzt || 1000000)}
                                    (${Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(user.usd || 2100)}`}
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className='flex flex-col gap-10'>
                                <button className='border-2  bg-[--profile_bt_bg] rounded-full mx-24 py-2 ps-2 shadow-lg shadow-gray-900'>
                                    <span className=' m-4 text-[--forward_arrow] bg-[--profile_bt_bg] rounded-full py-2 ps-2 pe-28 ' >{'>>>>>>>>'}</span>
                                </button>
                                <div className='grid h-fill w-full place-items-center'>
                                    <span>
                                        {PERSONAL_DATA.daylyLimit}
                                    </span>
                                    <span>
                                        {user.limit || 'XXXXXXX/YYYYY KZT'}
                                    </span>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </ReactModal>
            {modalController === MODAL_CONTROLS.modifyPersonalData && <PersonalData isOpen={isSubModalOpen} setIsOpen={setIsSubModalOpen} />}
            {modalController === MODAL_CONTROLS.help && <HelpCenter isOpen={isSubModalOpen} setIsOpen={setIsSubModalOpen} />}
            {modalController === MODAL_CONTROLS.support && <Support isOpen={isSubModalOpen} setIsOpen={setIsSubModalOpen} />}
        </>
    )
}