import { HiMiniBars4 } from "react-icons/hi2";
import cards from "../../images/cardsImage.png";
import React from "react";

const HelpCenterInfo = ({ title }) => {
  return (
    <div className="flex flex-col">
      <div className="w-full h-[20vw] desktop:h-[17vw] bg-[#245626] p-5 mt-[10%] rounded-xl relative">
        <HiMiniBars4 className="laptop:text-4xl desktop:text-6xl desktop2:text-8xl text-white" />
        <p className="laptop:text-xl desktop:text-2xl desktop2:text-4xl font-bold text-white mt-[7%] desktop:mt-[4%] ml-10">
          Справочный центр
        </p>
        <img loading="eager"
          src={cards}
          alt="cards"
          className="w-[25%] absolute  top-6 right-[1%]"
        />
      </div>
      <div className="bg-[#EAEAEA] text-xl desktop2:text-3xl text-center rounded-xl p-3 mt-[5%] shadow-[1px_5px_5px_1px_#9a9a9a]">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default HelpCenterInfo;
