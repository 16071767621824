import React from 'react'

export default function HomeSVG({fill, stroke}) {
  return (
    <svg width="11" height="10" viewBox="0 0 11 10" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path 
    d="M1.14453 9.01367H2.04453M2.04453 9.01367H4.74453M2.04453 9.01367V4.82928C2.04453 4.56771 2.04453 4.43685 2.07377 4.31514C2.09968 4.20727 2.14254 4.1052 2.2001 4.01296C2.26505 3.90887 2.35532 3.82256 2.53628 3.65031L4.69699 1.59362C5.03246 1.27431 5.20021 1.11464 5.38912 1.05387C5.55571 1.00027 5.73327 1.00027 5.89985 1.05387C6.08891 1.11468 6.2569 1.2745 6.59287 1.5943L8.75287 3.65031C8.93382 3.82255 9.02414 3.90887 9.08909 4.01296C9.14665 4.1052 9.18918 4.20727 9.21509 4.31514C9.24433 4.43685 9.24453 4.56771 9.24453 4.82928V9.01367M4.74453 9.01367H6.54453M4.74453 9.01367V7.05557C4.74453 6.51486 5.14747 6.07652 5.64453 6.07652C6.14159 6.07652 6.54453 6.51486 6.54453 7.05557V9.01367M6.54453 9.01367H9.24453M9.24453 9.01367H10.1445" 
    stroke={stroke}
    strokeLinecap="round" 
    strokeLinejoin="round"
/>
</svg>
  )
}

