import ButtonBack from '../../ux/Buttons/ButtonBack'
import TgIcon from "../../../images/tg.svg"
import WpIcon from "../../../images/wp.svg"
import IgIcon from "../../../images/ig.svg"
import ButtonCloseModal from '../../ux/Buttons/ButtonCloseModal'


function ContactsData({ openModal }) {

    return (
        <>
            <div className="fixed top-0 w-full h-full laptop:h-auto laptop:max-w-[500px] laptop:top-1/2 laptop:bottom-auto laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2 z-30 flex items-center bg-white laptop:rounded-3xl">
                <div className="w-full h-fit laptop:h-auto pt-16 pb-8 px-5 laptop:px-10 overflow-hidden">

                    <p className="text-3xl text-center mt-0 laptop:mt-0 mb-20">Контактные данные</p>


                    <div className="socials flex items-center justify-center gap-10 laptop:justify-between mb-14">
                        <a href="#" className="bg-[#7FAD81] rounded-md px-2 laptop:px-3 py-2">
                            <img className="w-full" src={TgIcon} alt="social" />
                        </a>
                        <a href="#" className="bg-[#7FAD81] rounded-md px-2 laptop:px-3 py-2">
                            <img className="w-full" src={WpIcon} alt="social" />
                        </a>
                        <a href="#" className="bg-[#7FAD81] rounded-md px-2 laptop:px-3 py-2">
                            <img className="w-full" src={IgIcon} alt="social" />
                        </a>
                    </div>

                    <a href="tel:77054083987" className="block w-fit mx-auto text-xl mb-10">
                        Связаться с нами
                        <br />
                        +7 705 408 3987
                    </a>


                    <ButtonCloseModal onClick={openModal} />

                    <ButtonBack className={'top-10 left-4'} onClick={openModal} />

                </div>
            </div>


            <div className={`fixed inset-0 z-10 bg-[#040202A6]`}></div>
        </>
    )
}


export default ContactsData