import React from "react";
import HelpCenterInfo from "../ux/HelpCenterInfo";
import PrevButton from "../ux/PrevButton";

const PublicOfferPage = () => {
  return (
    <>
      <div className="mobile:hidden laptop:block w-[80%] mx-auto font-monserrat">
        <HelpCenterInfo title={"Публичная оферта "} />
        <p className="text-justify mt-14">
          Lorem ipsum dolor sit amet consectetur. Mauris turpis ornare vitae
          sed. Cras urna dis commodo est aliquam vestibulum mi sed. Non arcu
          molestie elit faucibus leo et. Ut mattis elementum faucibus et id
          porttitor. Ac laoreet cras nulla tempus aliquam velit nec suspendisse
          orci. Auctor quis vestibulum vitae tempus et. Ac pellentesque
          fermentum ut imperdiet tristique est gravida. Velit arcu cum amet enim
          purus. Ultrices massa ultrices at nunc proin mi placerat hac.
          Venenatis sagittis adipiscing tellus aliquam pharetra sit urna. Est
          leo et eget ut lectus. Egestas rutrum malesuada libero mi mollis leo
          sodales lorem fames. Mollis sodales aliquam vulputate platea etiam
          faucibus in pellentesque. Amet lectus mattis vel est tortor
          ullamcorper. Dictumst quam nisl tempus maecenas. Cras et duis
          condimentum massa imperdiet eu. Morbi arcu etiam risus in purus. Quam
          imperdiet a dictum pellentesque laoreet amet. Velit sed viverra
          pharetra erat vel quam aliquam consectetur. Volutpat maecenas nisl
          tempor diam rutrum enim. Purus id rutrum mi ac pellentesque.
          Sollicitudin mattis feugiat pellentesque massa elementum rutrum eget
          et. Sed dui tortor mauris mollis. Leo scelerisque facilisis placerat
          amet. Ultricies nulla aliquet elementum tortor ipsum. Est leo ut at
          phasellus quis lectus erat volutpat vulputate. Suscipit in sed
          vestibulum vestibulum eu scelerisque. Et sagittis pretium neque varius
          duis purus vestibulum. Feugiat aliquet laoreet potenti condimentum
          pellentesque. Habitasse egestas bibendum ut rutrum eget vestibulum
          ultrices. A arcu eget lobortis ornare enim cursus amet in. Integer
          iaculis enim fermentum accumsan id nascetur leo. Ridiculus lorem
          tellus vestibulum dui in lacus velit odio cursus. Etiam non morbi
          faucibus vulputate. Mauris platea vel vulputate velit et ut laoreet
          morbi in. Condimentum hac posuere tortor rhoncus. Sem duis nibh nunc
          suspendisse suscipit placerat faucibus. Varius porttitor vestibulum ut
          convallis sed dolor nunc. Tincidunt diam scelerisque non pretium.
          Pulvinar aliquam vel id condimentum. Tortor lectus sapien viverra non
          pellentesque viverra tincidunt. Varius ultrices venenatis bibendum
          donec. Massa at volutpat elementum eu tempor. Ultrices bibendum sed
          egestas quam potenti. Ultrices auctor quis vestibulum condimentum
          congue ac. Sapien diam aliquam orci feugiat urna. Aenean lobortis sit
          aliquam tristique aliquam. Nisl suscipit habitant nunc arcu gravida.
          Pellentesque quam eget faucibus et luctus vel in morbi. Congue
          lobortis eu placerat porta eget convallis aliquam in. Nibh dui metus
          lorem et. Nulla molestie ultrices consequat ac pellentesque. A cursus
          interdum malesuada fermentum lacus dictum sed mauris. Vitae elementum
          arcu malesuada velit nunc malesuada. Pellentesque facilisis neque
          feugiat venenatis vitae aliquam nec in. Quam lacus et sed ornare quis
          scelerisque eu felis. Lectus morbi non tristique lectus porta lorem
          morbi arcu. Ultrices venenatis in eu ipsum placerat ipsum vulputate
          turpis fringilla. At lorem mattis faucibus a pulvinar ultricies
          consequat nulla tincidunt. Faucibus venenatis risus eu mattis porta
          eget integer. Vel mauris phasellus sem ut adipiscing faucibus ultrices
          nulla eu. Ac urna accumsan suspendisse sit. Erat rutrum in pulvinar
          gravida. Sociis malesuada ornare sit in. Quis interdum et erat ipsum
          at consectetur posuere quisque mauris. Arcu eleifend vel metus
          malesuada morbi aliquet tristique diam. Aliquam semper sit habitant
          sed ultrices suscipit pharetra quis fermentum. Non suspendisse
          scelerisque gravida blandit. Convallis fames amet scelerisque
          tristique eros. Eu sed ornare ut eget maecenas id porttitor diam sed.
          Dictum aliquam sit ut odio urna sollicitudin. Aliquet quis bibendum
          lectus nisi tellus pellentesque gravida. Fringilla leo et molestie
          netus nisl massa adipiscing cum molestie. Pellentesque sit congue in
          erat tortor ut. Enim etiam ullamcorper donec id egestas dui maecenas
          sapien consequat. Velit eleifend et eget nisi arcu enim elementum eu.
          Leo dapibus amet nullam vitae pellentesque. Non magnis tincidunt
          libero mi mattis. Sem aliquet sapien purus venenatis tristique laoreet
          nisi pretium egestas. Egestas quam quis phasellus sit ullamcorper enim
          consequat. Ullamcorper semper augue et sed malesuada neque fermentum
          egestas ut. Massa odio scelerisque metus sagittis ut risus. Morbi enim
          vel nunc mauris id.
        </p>
      </div>
      <div className="mobile:block laptop:hidden bg-[#2D2D35] h-[100vh] w-full font-monserrat">
        <div className="w-[90%] mx-auto pt-5 text-white">
          <PrevButton />
          <p className="text-2xl font-medium text-center my-5">
            Публичная оферта{" "}
          </p>
          <p className="text-justify">
            Lorem ipsum dolor sit amet consectetur. Sagittis turpis viverra ut
            venenatis tincidunt egestas et at placerat. Lectus amet neque augue
            magna vitae tellus feugiat blandit. In rhoncus nisl tellus pretium
            posuere at. Aliquam enim est augue nunc vitae et. Mi viverra
            penatibus elit senectus. Iaculis enim integer turpis porttitor
            molestie nunc.Lorem ipsum dolor sit amet consectetur. Sagittis
            turpis viverra ut venenatis tincidunt egestas et at placerat. Lectus
            amet neque augue magna vitae tellus feugiat blandit. In rhoncus nisl
            tellus pretium posuere at. Aliquam enim est augue nunc vitae et. Mi
            viverra penatibus elit senectus. Iaculis enim integer turpis
            porttitor molestie nunc.tellus feugiat blandit. In rhoncus nisl
            tellus pretium posuere at. Aliquam enim est augue nunc vitae et. Mi
            viverra penatibus elit senectus. Iaculis enim integer turpis
            porttitor molestie nunc.
          </p>
        </div>
      </div>
    </>
  );
};

export default PublicOfferPage;
