import CloseModalIcon from '../../../images/close.svg'

function ButtonCloseModal({ onClick, position = "-top-14 -right-14" }) {
  return (
    <button className={`fixed ${position}`} onClick={onClick}>
      <img src={CloseModalIcon} alt="CloseModal" />
    </button>
  )
}

export default ButtonCloseModal