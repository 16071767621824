import BankIcon from '../../../images/bankIcon.png'

function MyPaymentsItem({ image, operationType, sum }) {
    return (
        <div className={`relative w-full flex justify-between items-center px-4 laptop:px-7 py-3 ${operationType ? 'bg-[#FFFFFF]' : 'bg-[#FFFFFF]'} rounded-[10px] text-base shadow-[0px_2px_3.9px_0px_#00000021]`}>
            <div className="absolute top-1/2 left-0 -translate-y-1/2 bg-white w-[79px] h-[80px] rounded-2xl shadow-[0px_2px_3.9px_0px_#00000021]">
                <img src={BankIcon} alt="icon" />
            </div>
            <div className="ms-20 laptop:ms-[66px]">
                <p className="font-bold">Игры</p>
                <p className="font-thin">13 мая. 2024</p>
            </div>
            <span className="font-bold text-[red] text-lg">{operationType ? '+' : '-'}${sum}</span>
        </div>
    )
}

export default MyPaymentsItem

