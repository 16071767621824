import React from 'react';
import FOOTER from '../constants/footer.constants.js';
import homeWhite from '../images/footer/white-home-96.png';
import homeBlack from '../images/footer/black-home-96.png';
import walletWhite from '../images/footer/white-request-money-96.png';
import walletBlack from '../images/footer/black-request-money-96.png';
import personWhite from '../images/footer/white-person-96.png';
import personBlack from '../images/footer/black-person-96.png';
import timeWhite from '../images/footer/white-time-machine-96.png';
import timeBlack from '../images/footer/black-time-machine-96.png';

import {
  InstargramSVG,
  HomeSVG,
  TelegrammSVG,
  TiktokSVG,
  VkSVG,
  AppleDownloadSVG,
  GoogleDownloadSVG,
  EmailSVG,
  PhoneSVG
} from '../images/footer/';
import { Link,NavLink } from 'react-router-dom';

const styles = {
  active: ' -top-2/3  bg-[--white] special-bg rounded-b-full pb-5  absolute h-[75px] px-2 ',
  notActive: ' bottom-0 p-2 h-[50px] w-[50px] h-full absolute -top-1/4 ',
  activeImg: ' rounded-full bg-[--footer_mobile_img_bg] w-[50px] mb-5 p-2 ',
  notActiveImg: ' rounded-full  py-3 w-[50px] ',
  activeDiv: ' py-5 h-[100px] bg-[--footer_mobile_bg] ',
  notActiveDiv: ' bg-[--footer_mobile_bg] ',
  common: ' w-full p-3 min-h-[50px] max-h-[75px] '
}
export default function Footer({ tab }) {
  const [activeTab, setActiveTab] = React.useState(FOOTER.mobileMenu.home);

  React.useEffect(() => {
    tab ? setActiveTab(tab) : setActiveTab(FOOTER.mobileMenu.home)
  }, []);

  function handleTabChange(tabName) {
    setActiveTab(tabName);
  };

  return (
    <React.Fragment>
      {/* mobile */}
      <main className='laptop:hidden flex min-h-[50px] max-h-[75px] mt-7'>
        <nav className='rounded-xl flex w-full mx-5 h-full'>

          <section onClick={() => handleTabChange(FOOTER.mobileMenu.home)} className='h-full relative w-1/4 flex justify-center' >
            <div className={`${(activeTab === FOOTER.mobileMenu.home ? styles.active : styles.notActive)} `}>
              <img loading="eager"
                className={`${(activeTab === FOOTER.mobileMenu.home ? styles.activeImg : styles.notActiveImg)}`}
                alt='home'
                src={activeTab === FOOTER.mobileMenu.home ? homeBlack : homeWhite}
              />
              <span></span>
            </div>
            <div className={`${(activeTab === FOOTER.mobileMenu.home ? styles.activeDiv : styles.notActiveDiv)} ${styles.common} h-full`}>
            </div>
          </section>
          <section onClick={() => handleTabChange(FOOTER.mobileMenu.profile)} className='h-full relative w-1/4 flex justify-center' >
            <div className={`${(activeTab === FOOTER.mobileMenu.profile ? styles.activeDiv : styles.notActiveDiv)} ${styles.common} h-full`}>
            </div>
            <div className={`${(activeTab === FOOTER.mobileMenu.profile ? styles.active : styles.notActive)}  `}>
              <img loading="eager"
                className={`${(activeTab === FOOTER.mobileMenu.profile ? styles.activeImg : styles.notActiveImg)}`}
                alt='profile'
                src={activeTab === FOOTER.mobileMenu.profile ? personBlack : personWhite}
              />
              <span></span>
            </div>
          </section>
          <section onClick={() => handleTabChange(FOOTER.mobileMenu.wallet)} className='h-full relative w-1/4 flex justify-center' >
            <div className={`${(activeTab === FOOTER.mobileMenu.wallet ? styles.activeDiv : styles.notActiveDiv)} ${styles.common} h-full`}>
            </div>
            <div className={`${(activeTab === FOOTER.mobileMenu.wallet ? styles.active : styles.notActive)} `}>
              <img loading="eager"
                className={`${(activeTab === FOOTER.mobileMenu.wallet ? styles.activeImg : styles.notActiveImg)}`}
                alt='request money'
                src={activeTab === FOOTER.mobileMenu.wallet ? walletBlack : walletWhite}
              />
              <span></span>
            </div>
          </section>
          <section onClick={() => handleTabChange(FOOTER.mobileMenu.transactions)} className='h-full relative w-1/4 flex justify-center' >
            <div className={`${(activeTab === FOOTER.mobileMenu.transactions ? styles.activeDiv : styles.notActiveDiv)} ${styles.common} h-full`}>
            </div>
            <div className={` ${(activeTab === FOOTER.mobileMenu.transactions ? styles.active : styles.notActive)} `}>
              <img loading="eager"
                className={`${(activeTab === FOOTER.mobileMenu.transactions ? styles.activeImg : styles.notActiveImg)}`}
                alt='time machine'
                src={activeTab === FOOTER.mobileMenu.transactions ? timeBlack : timeWhite}
              />
              <span></span>
            </div>
          </section>
        </nav>
      </main>
      
      {/* desctop */}
      <main className='hidden font-monserrat bg-[--footer_bg] px-10 pt-28 pb-16 text-[--white] laptop:grid gap-3 text-[13px]'>
        <section className='flex gap-16'>
          {FOOTER.footerMenu.map(item => {
            return <div key={item.title} className='flex flex-col gap-6'>
              <h4 className='font-bold'><NavLink to={item.link}>{item.title}</NavLink></h4>
              <ul className='grid gap-4 font-normal'>
                {item.children.map(child => <NavLink to={child.link} key={child.title}>{child.title}</NavLink>)}
              </ul>
            </div>
          })}
        </section>
        <section>
          <div className='border rounded border-1 mb-6'></div>
        </section>
        <section className='flex justify-between w-full font-manrope'>
          <div className=''>
            <h3 className='mb-6 pl-7 font-normal font-sans text-sm'>
              <span>| </span>
              <Link to='/'>{FOOTER.main}</Link>
              <span> / </span>
              <Link to='/deposite'>{FOOTER.deposite}</Link>
            </h3>
            <ul className='grid gap-3 font-medium text-[11px]'>
              <li className='flex min-h-7 hover:stroke-black'>
                <span className='bg-[--white] w-4 h-4 rounded-2xl grid place-items-center mr-3'>
                  <HomeSVG fill='none' stroke='inherit' />
                </span>
                <div className="grid">
                  {FOOTER.contacts.adress}
                </div>
              </li>
              <li className='flex min-h-7 hover:stroke-black'>
                <span className='bg-[--white] w-4 h-4 rounded-2xl grid place-items-center mr-3'>
                  <EmailSVG fill='none' stroke='inherit' />
                </span>
                <div className="grid">
                  {FOOTER.contacts.emailList.map(item =>
                    <span key={item.title} className='flex gap-2'>
                      <a href={`mailto:${item.description}`}>{item.description}</a>
                      <span>- {item.title}</span>
                    </span>
                  )}
                </div>
              </li>
              <li className='flex min-h-7 hover:stroke-black'>
                <span className='bg-[--white] w-4 h-4 rounded-2xl grid place-items-center mr-3'>
                  <PhoneSVG fill='none' stroke='inherit' />
                </span>
                <div className="grid">
                  {FOOTER.contacts.phoneList.map(item =>
                    <span key={item.title} className='flex gap-2'>
                      <a href={`tel:${item.description}`}>{item.description}</a>
                      <span>- {item.title}</span>
                    </span>
                  )}
                </div>
              </li>
            </ul>
          </div>
          {/* <div className='flex flex-col gap-2.5'>
            <div className='flex gap-2.5'>
              <a href={FOOTER.socialLinks.instagram}><InstargramSVG fill='white' stroke='white' /></a>
              <a href={FOOTER.socialLinks.telegramm}><TelegrammSVG fill='white' stroke='white' /></a>
              <a href={FOOTER.socialLinks.tiktok}><TiktokSVG fill='white' stroke='white' /></a>
              <a href={FOOTER.socialLinks.vk}><VkSVG fill='white' stroke='white' /></a>
            </div>
            <div className='grid gap-2.5'>
              <a href={FOOTER.appStoreLink}><AppleDownloadSVG fill='#1E5420' stroke='white' /></a>
              <a href={FOOTER.googlePlayLink}><GoogleDownloadSVG fill='#1E5420' stroke='white' /></a>
            </div>
          </div> */}
        </section>
      </main>
    </React.Fragment>
  )
}
