import MyPaymentsItem from "./MyPaymentsItem"


function MyPayments() {
    return (
        <div className="w-full space-y-4 laptop:mt-1">
            {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => <MyPaymentsItem key={num} sum={num} operationType={num % 2 === 0} />)
            }
        </div>
    )
}

export default MyPayments