import React from 'react'

export default function PhoneSVG({fill, stroke}) {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path 
        d="M4.39568 1.64228C4.24382 1.26262 3.87612 1.01367 3.46723 1.01367H2.09188C1.56867 1.01367 1.14453 1.43772 1.14453 1.96094C1.14453 6.40829 4.74983 10.0137 9.19706 10.0137C9.72026 10.0137 10.1443 9.58949 10.1443 9.06627L10.1445 7.69066C10.1445 7.28175 9.89565 6.9141 9.516 6.76224L8.19781 6.23515C7.85679 6.09873 7.46851 6.16012 7.18635 6.39527L6.84614 6.67901C6.44883 7.01012 5.86423 6.98379 5.49852 6.61807L4.54055 5.6592C4.17485 5.29349 4.14782 4.70934 4.47892 4.31202L4.7626 3.97182C4.99774 3.68965 5.05968 3.30125 4.92327 2.96022L4.39568 1.64228Z" 
        stroke={stroke}
        strokeLinecap="round" 
        strokeLinejoin="round"
    />
</svg>
  )
}

