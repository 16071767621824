import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/headerLogo.png";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa6";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { AiFillTikTok } from "react-icons/ai";
// import userImage from "../../images/user.png";
import MODAL_CONTROLS from "../../constants/modalControls.constants";

const NavBar = ({ user, setIsOpen, setModalController }) => {
  const [isHelpCenter, setIsHelpCenter] = useState(false);

  return (
    <div
      className={`hidden laptop:flex w-[90%] mx-auto py-3 font-monserrat ${isHelpCenter ? "items-start" : "items-center"
        } justify-between`}
    >
      <div
        className={`w-[50%] text-sm desktop:text-lg desktop2:text-2xl flex ${isHelpCenter ? "items-start" : "items-center"
          } justify-between`}
      >
        <NavLink to={"/"} className="w-[10%]">
          <img src={logo} alt="logo" className="w-full" />
        </NavLink>
        <NavLink to={"/"} className="hover:text-gray-500 transition-colors duration-300">
          <p>Главная</p>
        </NavLink>
        <NavLink to={"/"} className="hover:text-gray-500 transition-colors duration-300">
          <p>Сервисы</p>
        </NavLink>
        <NavLink to={"/news/1"} className="hover:text-gray-500 transition-colors duration-300">
          <p>Новости</p>
        </NavLink>
        <div className="flex flex-col">
          <p
            className="hover:text-gray-500 transition-colors duration-300 cursor-pointer"
            onClick={() => setIsHelpCenter(!isHelpCenter)}
          >
            Справочный центр
          </p>
          {isHelpCenter && (
            <div className="text-xs desktop2:text-sm w-[90%] text-gray-400">
              <NavLink to={"/about-us"} onClick={() => setIsHelpCenter(false)}>
                <p className="mt-3 hover:text-black">О нас</p>
              </NavLink>
              <NavLink to={"/public-offer"} onClick={() => setIsHelpCenter(false)} >
                <p className="mt-3 hover:text-black">Публичная оферта</p>
              </NavLink>
              <NavLink to={"/faq"} onClick={() => setIsHelpCenter(false)}>
                <p className="mt-3 hover:text-black">
                  Часто задаваемые вопросы
                </p>
              </NavLink>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-3">
        {user ? (
          <>
            <img src={user.avatar_url} alt="user image" className="laptop:w-[40px] desktop:w-[60px] ml-2" />
            <p className="text-sm desktop2:text-lg">Привет, {user.nickname}</p>
            <div className="bg-[#00FF29] p-3 rounded-xl">
              <p className="underline text-xs desktop2:text-base">
                Идентифицированный
              </p>
            </div>
          </>
        ) : (
          <>
            {/* <div className="flex gap-2 text-4xl desktop2:text-5xl">
              <Link to={"/"}>
                <FaInstagramSquare color="#245626" />
              </Link>
              <Link to={"/"}>
                <FaTelegram color="#245626" />
              </Link>
              <Link to={"/"}>
                <FaSquareWhatsapp color="#245626" />
              </Link>
              <Link to={"/"}>
                <AiFillTikTok color="#245626" />
              </Link>
            </div> */}
            <div className="flex gap-2 text-xs desktop:text-sm desktop2:text-lg">
              <button
                onClick={() => { setIsOpen(true); setModalController(MODAL_CONTROLS.login) }}
                className="p-2 bg-[#FFDD2D] rounded-lg hover:bg-yellow-500 transition-colors duration-300"
              >
                Войти
              </button>
              <button
                onClick={() => { setIsOpen(true); setModalController(MODAL_CONTROLS.registrate) }}
                className="p-2 bg-[#FFDD2D] rounded-lg hover:bg-yellow-500 transition-colors duration-300"
              >
                Зарегистрироваться
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;