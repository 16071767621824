const MODAL_CONTROLS = {
    smsCode: 'smsCode',
    login: 'login',
    registrate: 'registrate',
    onlineIdentify: 'online identify',
    profile: 'profile',
    modifyPersonalData: 'modify personal data',
    support: 'support',
    help: 'help',
    check: 'check',
}
export default MODAL_CONTROLS;