import { useState } from 'react'
import ProfileImg from '../../images/profile.jpeg'
import BankCardIcon from '../../images/bank&Card.png'
import TransferCard from '../../components/ux/modal/TransferOperations/TransferCard'
import TransferWallet from '../ux/modal/TransferOperations/TransferWallet'
import ActiveOperations from '../../components/ux/modal/TransferOperations/ActiveOperations'
import VisaCard from '../ux/VisaCard'
import ButtonBack from '../ux/Buttons/ButtonBack'
import { useNavigate } from "react-router-dom";


function Transfer() {

    const [openTransferWallet, setOpenTransferWallet] = useState(false)
    const [openActiveOperations, setOpenActiveOperations] = useState(false)
    const [openTransferCard, setOpenTransferCard] = useState(false)

    const navigate = useNavigate()

    return (
        <>
            <section id="transferPage" className="transfer laptop:mt-6 laptop:mb-8 w-full h-full max-w-[600px] tablet:mx-auto laptop:max-w-[1560px] laptop:bg-[#245626] laptop:w-9/12 mx-auto px-5 py-5 flex flex-col laptop:flex-row items-center laptop:justify-center desktop:gap-10 laptop:rounded-2xl laptop:max-h-[600px]">
                <div className="hidden laptop:block max-w-[500px] relative -left-32 top-10">
                    <img src={BankCardIcon} alt="profileImg" />
                </div>

                <VisaCard className="mx-auto mb-5 laptop:mb-0 laptop:hidden" />

                <ButtonBack className={'top-8 left-4'} onClick={() => navigate("/")} />


                <div className="laptop:bg-white w-full max-w-[370px] laptop:px-5 laptop:py-8 h-auto rounded-xl">
                    <div className="hidden laptop:block w-28 h-28 mx-auto rounded-full overflow-hidden">
                        <img src={ProfileImg} alt="profile" />
                    </div>
                    <p className="hidden laptop:block text-center mb-8">@aru001</p>

                    <div className="space-y-5 mb-12">
                        <button onClick={() => setOpenTransferWallet(true)} className="bg-white laptop:bg-[#245626] text-black font-medium laptop:font-normal laptop:text-white w-full py-4 rounded-lg">
                            Перевести на кошелек
                        </button>
                        <button onClick={() => setOpenActiveOperations(true)} className="bg-white laptop:bg-[#245626] text-black font-medium laptop:font-normal laptop:text-white w-full py-4 rounded-lg">
                            Активные запросы
                        </button>
                        <button onClick={() => setOpenTransferCard(true)} className="bg-white laptop:bg-[#245626] text-black font-medium laptop:font-normal laptop:text-white w-full py-4 rounded-lg">
                            Перевести на карту
                        </button>
                    </div>
                </div>
            </section>


            {openTransferWallet && <TransferWallet openModal={() => { setOpenTransferWallet(false) }} />}
            {openActiveOperations && <ActiveOperations openModal={() => { setOpenActiveOperations(false) }} />}
            {openTransferCard && <TransferCard openModal={() => { setOpenTransferCard(false) }} />}

            {
                (openTransferWallet || openActiveOperations || openTransferCard)
                && <div className={`fixed inset-0 z-10 bg-[#040202A6]`}></div>
            }

        </>
    )
}

export default Transfer