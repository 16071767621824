import { useState } from 'react'
import MyPayments from './MyPayments'
import History from './History'
import PaymentCategories from '../PaymentCategories'


export default function MyPaymentsTransactions({toggleProfile}) {

    const [expand, setExpand] = useState(true)

    const [toggleState, setToggleState] = useState(1)

    const toggleTab = (index) => {
        setToggleState(index)
    }

    toggleProfile(toggleState)


    const activeTab = "font-bold !bg-[#34FF3C] rounded-xl z-20 relative max-laptop:left-[5px] max-laptop:-top-[6px] max-laptop:shadow-[0px_10px_4px_0px_#00000040]"

    return (
        <section className="relative z-10 transactions mb-6 laptop:mt-10 laptop:mb-20">
            <div className={`laptop:bg-[#CDCBCB4D] laptop:w-5/6 mx-auto laptop:px-10 pt-4 pb-6 ${expand ? 'laptop:h-fit' : 'laptop:h-[418px]'} laptop:overflow-hidden rounded-xl transition-all`}>


                <div className="relative flex justify-around items-center laptop:gap-x-10 *:pt-3 *:pb-4 mb-7 laptop:mb-3 *:basis-5/12 laptop:*:shadow-[0px_4px_4px_0px_#00000040] laptop:bg-transparent rounded-xl max-laptop:shadow-[0px_10px_4px_0px_#00000040] laptop:*:bg-white laptop:*:rounded-xl text-center bg-white">
                    <p onClick={() => toggleTab(1)} className={`${toggleState === 1 && activeTab}`}>Мои платежи</p>
                    <p onClick={() => toggleTab(2)} className={`${toggleState === 2 && activeTab} laptop:hidden`}>Категорий</p>
                    <p onClick={() => toggleTab(3)} className={`${toggleState === 3 && activeTab}`}>История</p>
                </div>


                <div className="flex  justify-center gap-x-10">

                    {(toggleState === 1 && window.innerWidth < 1024) && <MyPayments />}
                    {(toggleState === 2 && window.innerWidth < 1024) && <PaymentCategories />}
                    {(toggleState === 3 && window.innerWidth < 1024) && <History />}


                    {window.innerWidth >= 1024 &&
                        <>
                            <MyPayments />
                            <History />
                        </>
                    }


                </div>

            </div>

        </section>
    )
}
