import React from 'react'

export default function EmailSVGg({fill, stroke}) {
  return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path 
        d="M1.75564 1.51367L5.48809 3.81981L5.48933 3.82065C5.90377 4.06931 6.11112 4.19372 6.33822 4.24178C6.53895 4.28426 6.74995 4.28426 6.95068 4.24178C7.17799 4.19368 7.38593 4.06891 7.80111 3.81981C7.80111 3.81981 10.1951 2.31664 11.5334 1.51367M1.14453 6.41377V2.61377C1.14453 2.05372 1.14453 1.77348 1.27775 1.55957C1.39492 1.37141 1.58176 1.21854 1.81174 1.12267C2.07319 1.01367 2.4157 1.01367 3.10021 1.01367H10.1891C10.8736 1.01367 11.2154 1.01367 11.4768 1.12267C11.7068 1.21854 11.8943 1.37141 12.0114 1.55957C12.1445 1.77327 12.1445 2.05317 12.1445 2.61213V6.41546C12.1445 6.97442 12.1445 7.25391 12.0114 7.46761C11.8943 7.65578 11.7068 7.80891 11.4768 7.90479C11.2156 8.01367 10.874 8.01367 10.1909 8.01367H3.0982C2.41503 8.01367 2.07293 8.01367 1.81174 7.90479C1.58176 7.80891 1.39492 7.65578 1.27775 7.46761C1.14453 7.2537 1.14453 6.97382 1.14453 6.41377Z" 
        stroke={stroke}
        strokeLinecap="round" 
        strokeLinejoin="round"
    />
</svg>
  )
}

