import { useState } from 'react'
import CloseModal from '../../../images/close.svg'
import Done from '../../../images/done.png'
import ButtonBack from '../../ux/Buttons/ButtonBack'
import { changeUserPassword } from '../../../API-services/user.services'

function ChangePassword({ openModal }) {

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [passwordChangeTry, setPasswordChangeTry] = useState(false)
    const [passwordChangedSuccess, setPasswordChangedSuccess] = useState(false)
    const [messageHint, setMessageHint] = useState('')


    const handleRequestChangePassword = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            alert("Новый пароль не совпадает");
            return;
        }

        const payload = {
            oldPass: currentPassword,
            newPass: newPassword,
            confirmPass: confirmPassword
        };

        const { success, message } = await changeUserPassword(payload)


        setPasswordChangeTry(true)

        if (success) {
            setMessageHint('Ваш пароль успешно изменен')
            setPasswordChangedSuccess(success)
        } else {
            setPasswordChangedSuccess(false)
            setMessageHint(message)
        }

        setTimeout(() => {
            setPasswordChangeTry(false)

            if (success) {
                setCurrentPassword('')
                setNewPassword('')
                setConfirmPassword('')
            }
        }, 2500)

    }

    return (
        <>
            <div className="fixed top-0 w-full h-full laptop:h-auto laptop:max-w-[500px] laptop:top-1/2 laptop:bottom-auto laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2 z-30">
                <form onSubmit={handleRequestChangePassword} className="relative w-full h-full laptop:h-auto pt-12 pb-8 px-5 laptop:px-10 laptop:rounded-3xl bg-white overflow-hidden">

                    <p className="hidden laptop:block text-3xl font-thin mb-4 text-center">Изменить пароль</p>
                    <p className="laptop:hidden text-2xl font-medium mt-12 mb-10">Сменить пароль</p>


                    <label className="laptop:font-medium block">
                        Старый пароль
                        <input
                            type="text"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            className="block mt-2 w-full px-4 py-3 text-2xl rounded-[12px] outline-none bg-[#D9D9D987]"
                            required />
                    </label>
                    <div className="mt-14 space-y-5 laptop:*:font-medium *:block mb-6">
                        <label>
                            Новый пароль
                            <input
                                type="text"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="block mt-2 w-full px-4 py-3 text-2xl rounded-[12px] outline-none bg-[#7FAD81]"
                                required />
                        </label>

                        <label>
                            Повторите пароль
                            <input
                                type="text"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="block mt-2 w-full px-4 py-3 text-2xl rounded-[12px] outline-none bg-[#7FAD81]"
                                required />
                        </label>
                    </div>

                    <button type='submit' className="block mx-auto bg-[#FFDD2D] font-medium laptop:font-semibold px-16 pt-2 pb-3  text-xl laptop:text-2xl rounded-3xl">
                        Изменить
                    </button>

                    <button className="fixed -top-14 -right-14" onClick={openModal}>
                        <img src={CloseModal} alt="CloseModal" />
                    </button>

                    <ButtonBack className={'top-5 left-4'} onClick={openModal} />


                    {passwordChangeTry &&
                        <>
                            <div className="absolute inset-0 backdrop-blur-sm flex justify-center items-center">
                            </div>

                            <p className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center bg-[#655D65B2] backdrop-blur py-4 px-6 w-2/3 laptop:w-auto rounded-md">
                                {
                                    passwordChangedSuccess ?
                                        <>
                                            <img className='mx-auto' src={Done} alt="done" />
                                            {messageHint}
                                        </> :
                                        <>
                                            {messageHint}
                                        </>
                                }
                            </p>
                        </>
                    }

                </form>
            </div>


            <div className={`fixed inset-0 z-10 bg-[#040202A6]`}></div>
        </>
    )
}


export default ChangePassword