import React, { useState } from "react";
import HelpCenterInfo from "../ux/HelpCenterInfo";
import PrevButton from "../ux/PrevButton";
import { Link } from "react-router-dom";

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const questions = [
    "Как открыть кошелек?",
    "Как пополнить?",
    "Как перевести другу?",
    "Как пройти идентификацию?",
    "Как отказаться в обслуживании?",
    "Как пополнить?",
    "Как перевести другу?",
  ];

  const answers = [
    "Lorem ipsum dolor sit amet consectetur. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Lorem ipsum dolor sit amet consectetur. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl.",
    "Lorem ipsum dolor sit amet consectetur.  Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl.",
    "Lorem ipsum dolor sit amet consectetur. Neque in  Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl.",
    "Lorem ipsum dolor sit amet consectetur. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl.",
    "Lorem ipsum dolor sit amet consectetur.  Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl.",
    "Lorem ipsum dolor sit amet consectetur. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed. Metus proin sed diam velit pellentesque fames. Ut luctus ridiculus ut aliquam mauris viverra eget. Ultrices commodo tincidunt ut ullamcorper sapien augue vitae. Nibh id eu ut lorem nisl consectetur varius. Diam arcu tincidunt faucibus posuere vitae eu sed cursus ut. Morbi volutpat tincidunt proin volutpat dis habitasse pharetra nisl.",
    "Lorem ipsum dolor sit amet consectetur. Neque in vestibulum augue congue quis semper tincidunt fringilla. Faucibus et facilisis suspendisse commodo morbi egestas ipsum sed.",
  ];

  return (
    <>
      <div className="mobile:hidden laptop:block w-[80%] mx-auto font-monserrat desktop2:text-2xl">
        <HelpCenterInfo title={"Часто задаваемые вопросы "} />
        <div className="mt-14 flex justify-between">
          <div className="w-[45%] bg-[#245626] px-2 py-6 mb-5 rounded-lg self-start">
            {questions.map((el, index) => (
              <div
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`bg-white p-4 mb-2 rounded-xl last:m-0 cursor-pointer hover:bg-[#42D948] transition-colors duration-300 ${activeIndex === index ? "bg-[#42D948]" : ""
                  }`}
              >
                <p>{el}</p>
              </div>
            ))}
          </div>
          <div className="w-[50%] bg-[#3A383D] p-6 rounded-xl">
            <div className="h-full bg-white px-4 py-6 rounded-lg">
              <p className="text-center mb-10">{questions[activeIndex]}</p>
              <p className="text-justify">{answers[activeIndex]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile:block laptop:hidden bg-[#2D2D35] h-[100vh] w-full font-monserrat">
        <div className="w-[90%] mx-auto pt-5 text-white">
          <Link>
            <PrevButton />
          </Link>
          <p className="text-2xl font-medium text-center my-5">
            Часто задаваемые <br /> вопросы
          </p>
          <p className="text-justify">
            Lorem ipsum dolor sit amet consectetur. Sagittis turpis viverra ut
            venenatis tincidunt egestas et at placerat. Lectus amet neque augue
            magna vitae tellus feugiat blandit. In rhoncus nisl tellus pretium
            posuere at. Aliquam enim est augue nunc vitae et. Mi viverra
            penatibus elit senectus. Iaculis enim integer turpis porttitor
            molestie nunc.Lorem ipsum dolor sit amet consectetur. Sagittis
            turpis viverra ut venenatis tincidunt egestas et at placerat. Lectus
            amet neque augue magna vitae tellus feugiat blandit. In rhoncus nisl
            tellus pretium posuere at. Aliquam enim est augue nunc vitae et. Mi
            viverra penatibus elit senectus. Iaculis enim integer turpis
            porttitor molestie nunc.tellus feugiat blandit. In rhoncus nisl
            tellus pretium posuere at. Aliquam enim est augue nunc vitae et. Mi
            viverra penatibus elit senectus. Iaculis enim integer turpis
            porttitor molestie nunc.
          </p>
        </div>
      </div>
    </>
  );
};

export default FAQPage;
