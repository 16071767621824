import HistoryItem from "./HistoryItem"
import ChartMobile from '../../../components/ux/Charts/ChartMobile'

function History() {
    return (
        <div className="w-full tablet:max-w-2xl laptop:w-full mx-auto space-y-3 laptop:mt-1">

            <ChartMobile />

            {
                [1, 2, 3, 4, 5, 6].map((num) => <HistoryItem key={num} sum={num} operationType={num % 2 === 0} />)
            }
        </div>
    )
}

export default History