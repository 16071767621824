export const API = 'https://api.open-trade.garcom.kz/wallet/production/v1';
export const USERS = '/users';
export const AUTH = '/users/auth';
export const REGISTER = '/users/registration';
// export const SMS_CODE = '/sms';
// export const PHONE = '/phone;'
export const USER_ID = '/users/info/';
export const EDIT_USER = '/users/edit';
export const EDIT_EMAIL = '/users/add-email';
export const CHANGE_PASSWORD = "/users/change-password"
export const ADD_CARD = "/users/cards"
export const TOP_UP_BALANCE = "/wallets/top-up"
export const CREATE_TRANSACTION = "/transactions"
export const GET_ALL_NOTIFICATIONS = "/notifications/"