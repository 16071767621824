import React, { useEffect, useState } from "react";
import SliderNews from "../ux/SliderNews";
import { useParams } from "react-router-dom";
import PrevButton from "../ux/PrevButton";
import { Link } from "react-router-dom";

const NewsPage = () => {
  const { id } = useParams();

  const [news, setNews] = useState([]);
  const [activeNews, setActiveNews] = useState([]);

  const getNewsInfo = async () => {
    try {
      const response = await (
        await fetch("https://api.open-trade.garcom.kz/wallet/production/v1/news")
      ).json();
      if (response.news) {
        setNews(response.news);
        const selectNews = response.news.filter((el) => el.id === Number(id));
        setActiveNews(selectNews);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNewsInfo();
  }, [id]);

  return (
    <div className="bg-[#2D2D35] laptop:bg-transparent font-monserrat">
      <div className="w-[600px] max-w-[90%] mx-auto laptop:max-w-full laptop:w-[95%] laptop:ml-[5%] laptop:mt-10">
        <div className="flex justify-between">
          <div className="mt-5 laptop:m-0 laptop:w-[55%]">
            <div className="laptop:hidden">
              <Link to='/news'>
                <PrevButton />
              </Link>
            </div>
            <p className="text-[#FFFFFF] text-4xl mt-5 laptop:hidden"> Новости </p>
            {activeNews.length > 0 &&
              activeNews.map((el) => (
                <div key={el.id} className="laptop:w-[90%] text-[#1EFF93] laptop:text-[#000000] mt-5" >
                  <p className="mobile:text-xl laptop:text-2xl desktop2:text-3xl font-medium">
                    {el.title}
                  </p>
                  <img loading="eager"
                    src={`https://api.open-trade.garcom.kz/wallet/production/v1/news/preview/${el.image_url}`}
                    alt="news picture"
                    className="w-full h-full rounded-xl object-cover mt-5 mb-10"
                  />
                  <p className="mobile:text-sm tablet:text-xl desktop2:text-2xl text-justify mb-5"> {el.content} </p>
                </div>
              ))}
          </div>
          <div className="mobile:hidden laptop:flex w-[40%] bg-[#245626] justify-center self-start">
            {window.innerWidth > 1024 && (<SliderNews news={news} isVertical={true} />)} </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
