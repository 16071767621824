import React from 'react'
import ReactModal from 'react-modal';
import CREATE_WALLET from '../../../constants/createWallet.constants';
import SMS_CODE from '../../../constants/smsCodeEnter.constants';
import LoaderIphoneSpinner from '../../ui/LoaderIphoneSpinner';
import { login, sendSMSCode } from '../../../API-services/user.services';

export default function SmsCodeEnter({ isOpen, setIsOpen, phone, password }) {
  const [smsCode, setSmsCode] = React.useState([]);
  const [finishTime] = React.useState(Date.now() + 60000);
  const [[diffM, diffS], setDiff] = React.useState([0, 0]);
  const [tick, setTick] = React.useState(false);

  React.useEffect(() => {
    const diff = (finishTime - new Date()) / 1000;
    if (diff < 0) return
    setDiff([
      Math.floor((diff / 60) % 60),
      Math.floor(diff % 60)
    ])
  }, [tick, finishTime])

  React.useEffect(() => {
    const timerID = setInterval(() => setTick(!tick), 1000);
    return () => clearInterval(timerID);
  }, [tick])

  async function handleSubmit(event) {
    event.preventDefault();

    // const response = await sendSMSCode(smsCode.join(''));
    // if (response.success) {
    //   setIsOpen(false);
    //   login(phone, password);
    // } else {
    //   setIsOpen(true);
    // }
  }

  function handleInputChange(index, value) {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }
    setSmsCode((prevSmsCode) => {
      const updatedSmsCode = [...prevSmsCode];
      updatedSmsCode[index] = value;
      return updatedSmsCode;
    });

    if (value && index < 5) {
      document.getElementById(`smsCodeInput${index + 1}`).select();
    } else if (index > 0 && !value) {
      document.getElementById(`smsCodeInput${index - 1}`).select();
    } else {
      console.log('send request');
      setIsOpen(false);
    }
  }

  return (
    <ReactModal
      style={CREATE_WALLET.customStyles}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      appElement={document.getElementById('modal')}
    >
      <div className="hidden laptop:flex flex-col gap-6 justify-center items-center text-base font-bold ">
        <div className="text-3xl font-light text-center">
          <LoaderIphoneSpinner />
        </div>
        <span className='text-sm font-medium font-[#204722]'>
          {SMS_CODE.text}
        </span>
        <form className='grid w-full px-10' onSubmit={handleSubmit}>
          <section className='flex justify-between'>
            {[0, 1, 2, 3, 4, 5].map((index) => (
              <input
                key={index}
                type="number"
                value={smsCode[index]}
                onChange={(event) => handleInputChange(index, event.currentTarget.value)}
                className={`max-w-10 max-h-10 bg-[--btn_color] rounded-xl p-2 ${index === 0 ? 'autofocus' : ''}`}
                maxLength={1}
                id={`smsCodeInput${index}`}
              />
            ))}
          </section>
        </form>
        <section className='grid place-items-center font-normal m-6'>
          {(diffM > 0 || diffS > 0) && <>
            <p className='text-[13px]'>{SMS_CODE.resend}</p>
            <p className='text-sm'>
              {`${diffM.toString().padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}
            </p>
          </>}
          {diffM <= 0 && diffS <= 0 &&
            <button
              id='submit-smscode-btn'
              type='button'
              value='button'
              className='bg-[--btn_color] text-[--black] font-normal py-2 px-8 rounded-xl mt-5'
              onClick={(event) => handleSubmit(event)}
            >
              {SMS_CODE.accept}
            </button>
          }
        </section>
      </div>
    </ReactModal>
  );
};