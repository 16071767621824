import React from 'react';
import ReactModal from 'react-modal';
import CREATE_WALLET from '../../../constants/createWallet.constants';
import WELCOME from '../../../constants/welcome.constants';
import PhoneInput from 'react-phone-input-2';
import MODAL_CONTROLS from '../../../constants/modalControls.constants';
import { phoneValidate } from '../../../utilits/utilits.js';
import { login } from '../../../API-services/user.services.js';
import EyeSvg from '../../../images/createWallet/Eye-svg.jsx';
import EyeSlashSvg from '../../../images/createWallet/Eye-slash-svg.jsx';

export default function Welcome({ isOpen, setIsOpen, callback }) {
  const [phoneNumber, setPhoneNumber] = React.useState('7');
  const [password, setPassword] = React.useState('');
  const [isPasswordShown, setIsPasswordShown] = React.useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsOpen(false);
    // callback(MODAL_CONTROLS.smsCode);
    await login(phoneNumber, password);

  }
  function handlePhoneChange(phoneNumber) {
    if (!phoneNumber === '') setPhoneNumber('7');
    else setPhoneNumber(phoneNumber);
  }

  return (
    <ReactModal
      style={CREATE_WALLET.customStyles}
      appElement={document.getElementById('modal')}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
        <div className="hidden laptop:flex flex-col gap-6 justify-center items-center text-base font-bold ">
        <h2 className="text-3xl font-light text-center">
          {WELCOME.wellcome}
        </h2>
        <form className='grid w-full px-10' onSubmit={handleSubmit}>
          <section className='grid w-full'>
            <PhoneInput 
              name='phoneNumber'
              specialLabel={CREATE_WALLET.phoneNumber}
              country='kz'
              placeholder='+7 (' 
              value={phoneNumber} 
              onChange={handlePhoneChange} 
              inputClass='bg-[--input_bg] rounded-xl px-2 py-2 w-full'
              disableCountryCode={false}
            />
          </section>
          <section className='grid w-full relative '>
            <label name='password'>{CREATE_WALLET.password}</label>
            <input name='password' required value={password} onChange={(event) => setPassword(event.currentTarget.value)} type={isPasswordShown ? "text":"password"} className='px-2 bg-[--input_bg] rounded-xl py-2'/>
            <div className='absolute right-3 inset-y-8 cursor-pointer' onClick={() => setIsPasswordShown(!isPasswordShown)}>
              {isPasswordShown && <EyeSlashSvg />}
              {!isPasswordShown && <EyeSvg />}
            </div>
          </section>
          <section className='grid place-items-center'>
            <button disabled={!phoneValidate(phoneNumber) && !password} type='submit' className='bg-[--btn_color] text-[--black] font-normal py-2 px-8 rounded-xl mt-5'>
              {WELCOME.login}
            </button>
          </section>
          <section className='grid place-items-center text-[15px] mt-5 text-[#518A54]'>
            <span className=' font-normal'>{WELCOME.youHaveNoAccount}</span>
            <span className='font-extrabold text-[#245626] cursor-pointer' onClick={() => callback(MODAL_CONTROLS.registrate)}>{WELCOME.registerNow}</span>
          </section>
        </form>
      </div>
    </ReactModal>
  )
}
