const CREATE_WALLET = {
    createWallet: 'Создай свой кошелек',
    nickname: 'Никнейм',
    phoneNumber: 'Номер телефона',
    password: 'Пароль',
    agreement: 'Я согласен с ',
    offert: 'публичной офертой',
    create: 'Создать',
    customStyles: {
        content: {
          zIndex: 20,
          backgroundColor: 'white',
          maxWidth: '500px',
          top: '40%',
          borderRadius: '24px',
          left: '50%',
          overflow: 'auto',
          transform: 'translate(-50%, -50%)',
        },
        overlay: {
          background: 'none',
          backdropFilter: 'blur(10px)',
      
        },
      }
}
export default CREATE_WALLET;