import { Link } from 'react-router-dom'

function PaymentCategories() {
    const payments = [
        { name: "Игры", link: "#" },
        { name: "Мобильная связь", link: "#" },
        { name: "Перевод на карту", link: "#" },
        { name: "Финансы", link: "#" },
        { name: "Транспорт", link: "#" },
        { name: "Ком.услуги + интернет", link: "#" },
        { name: "Социальные сети", link: "#" },
        { name: "Обьявления и хостинг", link: "#" },
        { name: "Охрана", link: "#" },
        { name: "Подарочные карты", link: "#" },
        { name: "Путешествие", link: "#" },
        { name: "Другое", link: "#" }
    ]

    return (
        <div className="grid grid-cols-3 gap-3 w-full min-[375px]:w-11/12">
            {
                payments.map(
                    item => <Link key={item.name} to={item.link} className="item bg-white leading-5 h-[78px] rounded-2xl flex items-center justify-center text-center text-base">{item.name}</Link>
                )
            }
        </div>
    )
}

export default PaymentCategories