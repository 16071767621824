import ButtonCloseModal from '../../Buttons/ButtonCloseModal'
import BankCardIcon from '../../../../images/bank&Card.png'
import ProfileImg from '../../../../images/profile.jpeg'
import VisaCard from '../../VisaCard'
import ButtonBack from '../../Buttons/ButtonBack'


function ViaFriend({ openModal }) {

    return (
        <>
            <div className="fixed w-full laptop:w-9/12 top-0 left-0 laptop:top-1/2 laptop:left-1/2 laptop:-translate-x-1/2 laptop:-translate-y-1/2 bg-[#3a383d] laptop:bg-white flex flex-col laptop:flex-row justify-evenly items-center laptop:py-14 h-full  laptop:max-h-[600px] laptop:rounded-2xl z-20 laptop:px-5">
                <div className="hidden laptop:block relative -left-16 top-8 w-full max-w-[450px]">
                    <img src={BankCardIcon} alt="BankAndCard" />
                </div>

                <form className="bg-[#3a383d] laptop:bg-[#665f5f] w-full max-w-[600px] laptop:max-w-[400px] px-5 py-4 laptop:py-8 h-full laptop:rounded-xl">
                    <div className="hidden laptop:block w-28 h-28 mx-auto rounded-full overflow-hidden mb-4">
                        <img src={ProfileImg} alt="profile" />
                    </div>

                    <VisaCard className="mx-auto laptop:hidden left-1 mb-6" />

                    <div className="space-y-4">
                        <input type="text" className="block w-full px-5 py-4 rounded-xl outline-none" placeholder="Никнейм" />

                        <input type="text" className="block w-full px-5 py-4 rounded-xl outline-none" placeholder="0т" />

                        <textarea className="block w-full px-5 py-4 rounded-xl outline-none resize-none" placeholder="Комментарий"></textarea>

                        <button className="px-14 py-3 font-medium block mx-auto text-lg rounded-xl bg-[#FFDD2D]">
                            Отправить
                        </button>
                    </div>
                </form>

                <ButtonBack onClick={openModal} className={'top-10 left-4 min-[375px]:top-10 min-[375px]:left-4 tablet:left-10'} />
                <ButtonCloseModal onClick={openModal} position='hidden laptop:block -top-5 -right-5' />

            </div>
        </>
    )
}


export default ViaFriend