import React from "react";
import { useNavigate } from "react-router-dom";

const HelpCenterPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-help-center bg-no-repeat bg-center bg-cover w-full h-[100vh] flex justify-center items-center font-monserrat laptop:hidden">
      <div className="w-[600px] max-w-[80%] flex flex-col">
        {[
          { route: "/about-us", name: "О нас" },
          { route: "/public-offer", name: "Публичная оферта" },
          { route: "/faq", name: "FAQ" },
        ].map((el) => (
          <button
            key={el.name}
            className="bg-white mt-4 p-5 rounded-xl font-medium shadow-[1px_2px_5px_1px_#9a9a9a] text-black desktop2:text-3xl hover:bg-[#34FF3C] transition-colors duration-300"
            onClick={() => navigate({ pathname: `${el.route}` })}
          >
            {el.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HelpCenterPage;
